//@flow
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import {v4}  from 'uuid';
import cns from 'classnames/bind';

import styles from './TextInput.module.scss';

const cx = cns.bind(styles);

type TextAreaInputProps = {
  fieldName?: string;
  label: string;
  onChange: (name: string, value: any, e: any) => void;
  value: string;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
}

const TextAreaInput = React.memo<TextAreaInputProps>((props: TextAreaInputProps) => {
  const { fieldName, label, onChange, value, containerClassName, labelClassName, inputClassName } = props;

  const uniqueId = v4();
  
  let computedOnChange = null;
  if(onChange) {
    computedOnChange = (e) => onChange(e.target.value, e);
  }

  return (
    <FormGroup className={cx(containerClassName)}>
      {!isEmpty(label) && <Label className={cx(styles.label, labelClassName)} for={uniqueId}>{label}</Label> }
      <Input className={cx(styles.input, inputClassName)}
             type='textarea'
             name={fieldName}
             id={uniqueId}
             defaultValue={value}
             onChange={computedOnChange} />
    </FormGroup>
  );
});

export { TextAreaInput };
