//@flow
import React, { PureComponent, Fragment } from 'react';
import { Jumbotron } from 'reactstrap';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';

import { IMAGES, constructCloudinaryUrl } from '../../utils/ImageUtils';
import { getAlgoliaAppId, getAlgoliaClientId } from '../../utils/ConfigUtils';
import { QbSearchContent } from './QbSearchContent';
import { Geolocation } from '../../components/Geolocation';

import styles from './Search.module.scss';
import { Logger } from '../../utils/LoggerService';


const ONE_MINUTE_IN_MILLISECONDS: number = 60 * 1000;

type SearchProps = {
  history: any;
  aroundLatLng: any;
}

type SearchState = {
  timestamp: number;
  localSearchState: Object;
}

class Search extends PureComponent<SearchProps, SearchState> {
  
  rerenderInterval: any;
  
  constructor(props: SearchProps) {
    super(props);
    
    this.state = {
      timestamp: 0,
      localSearchState: {}
    };
  }
  
  componentDidMount() {
  
    this._startTimer();
  }
  
  componentDidUpdate(prevProps: SearchProps) {
  
    this._startTimer();
  }
  
  componentWillUnmount(): void {
  
    this._stopTimer()
  }
  
  _startTimer = () => {
  
    if(!this.rerenderInterval) {
      this.rerenderInterval = setInterval(() => {
    
        this.setState({timestamp: Date.now()});
        Logger.info('Updating timestamp');
      }, ONE_MINUTE_IN_MILLISECONDS)
    }
  };
  
  _stopTimer = () => {
  
    if(this.rerenderInterval) {
      clearInterval(this.rerenderInterval);
      this.rerenderInterval = null;
    }
  };
  
  handleClearSearch = () => {
  
    const copiedSearchState = {...this.state.localSearchState};
    
    copiedSearchState.query = '';
    
    this.setState({localSearchState: copiedSearchState});
  };
  
  /**
   *
   * @param {Object} searchState
   */
  _onSearchStateChange = (searchState: Object) => {
    console.log(`Calling _onSearchStateChange. [SearchState: ${JSON.stringify(searchState)}]`);
    
    this.setState({localSearchState: searchState});
  };
  
  _onRestaurantClick = (restaurantData: Object) => {
    console.log('_onRestaurantClick');
    
    if(restaurantData.slug) {
  
      this.props.history.push(`/restaurant/s/${restaurantData.slug}`);
    } else {
      this.props.history.push(`/restaurant/${restaurantData._id}`);
    }
    
  };
  
  render() {
  
    let configuration = (<Configure getRankingInfo={true} aroundLatLngViaIP={true} aroundRadius='all' />);
    if(!isEmpty(this.props.aroundLatLng)) {
      configuration = (<Configure getRankingInfo={true} aroundLatLng={this.props.aroundLatLng} />);
    }
    
    return (
      <Fragment>
        <Helmet>
          <title>QuickBite - Search</title>
          <meta name='description' content="Search page for restaurants" />
        </Helmet>
        <Geolocation />
        <InstantSearch appId={getAlgoliaAppId()}
                       apiKey={getAlgoliaClientId()}
                       onSearchStateChange={this._onSearchStateChange}
                       searchState={this.state.localSearchState}
                       indexName='Restaurants'>
          {configuration}
          <Jumbotron className={styles.jumbo} style={{backgroundSize: 'cover', backgroundImage: `url(${constructCloudinaryUrl(IMAGES.LUNCH.ONE, {h: 300, w: 1400, crop: 'fill', dpr: 'auto', tintPercentage: 25, tintColor: 'rgb:3e2222'})})`}}>
            <h1 className='display-3'>QuickBite!</h1>
            <hr className="my-2" />
            <SearchBox translations={{
                         placeholder: 'What are you hungry for?'
                       }}
                       searchAsYouType={false} />
          </Jumbotron>
          
          <QbSearchContent onHitClick={this._onRestaurantClick} timestamp={this.state.timestamp} onSearchClear={this.handleClearSearch} />
        </InstantSearch>
      </Fragment>
    );
  }
}

export { Search };
