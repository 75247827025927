//@flow
import React, { PureComponent } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { orderBy, isEqual, get, isEmpty, has } from 'lodash';
import cns from 'classnames/bind';

import { SearchResultItem } from './SearchResultItem';
import type { ComputedScheduleDataType, RestaurantType } from '../../types';
import { getOpenInfoForCurrentTime } from '../../utils/TimeUtils';
import { Logger } from '../../utils/LoggerService';

import styles from './QbInfiniteHits.module.scss';

const cx = cns.bind(styles);

type QbInfiniteHitsProps = {
  hits: any;
  hasMore: boolean;
  refine: any;
  onHitClick: any;
  timestamp: number;
}

type QbInfiniteHitsState = {
  sortedHits: any[];
}

const isOpenNowSelector = (restaurant: {openCloseData: ComputedScheduleDataType}) => {
  
  const isOpenNow = get(restaurant, 'openCloseData.isOpenNow', false) || false;
  const isOpenLaterToday = get(restaurant, 'openCloseData.isOpenLaterToday', false) || false;
  if(isOpenNow) {
    return 2;
  } else if(isOpenLaterToday) {
    return 1;
  } else {
    return 0;
  }
};


class QbInfiniteHits extends PureComponent<QbInfiniteHitsProps, QbInfiniteHitsState> {
  
  constructor(props: QbInfiniteHitsProps) {
    super(props);
    
    this.state = {
      sortedHits: []
    };
  }
  
  componentDidMount() {
    try {
      Logger.info('ElementHits.hits has loaded.  Calculating sortedHits.');
      const sortedHits = this.computeSortedHits(this.props.hits);
    
      this.setState({
        sortedHits: sortedHits
      });
    } catch(err) {
      Logger.error('Unexpected error in QbInfiniteHits.componentDidMount', err);
    }
  }
  
  componentDidUpdate(prevProps: QbInfiniteHitsProps) {
    try {
      if(!isEqual(this.props.hits, prevProps.hits) || this.props.timestamp !== prevProps.timestamp) {
        Logger.info('ElementHits.hits has updated.  Recalculating sortedHits');
  
        const sortedHits = this.computeSortedHits(this.props.hits);
  
        this.setState({
          sortedHits: sortedHits
        });
      }
  
    } catch(err) {
      Logger.error('Unexpected error in QbInfiniteHits.componentDidUpdate', err);
    }
  }
  
  /**
   *
   * @param hits
   * @returns {Array}
   */
  computeSortedHits = (hits: any[]) => {
    const newHits = [];
    hits.forEach(hitItem => {
      
      const openCloseData = this._generateOpenData(hitItem);
  
      const openRestaurantLocationId = get(openCloseData, 'restaurantLocationId', '') || '';
      const isRestaurantOpenNow = get(openCloseData, 'isOpenNow', false) || false; /*? */
      
      // if restaurant doesn't match the open restaurant, then don't display
      // if restaurant doesn't match, and its closed and is default, then show
      if(has(hitItem, 'isDefaultLocation')) /*? */ {
        if ((openRestaurantLocationId !== hitItem.restaurantLocationId) &&
          !(openRestaurantLocationId !== hitItem.restaurantLocationId &&
            !isRestaurantOpenNow &&
            has(hitItem, 'isDefaultLocation') &&
            hitItem.isDefaultLocation)) {
          return;
        }
      }
  
      newHits.push({
        ...hitItem,
        distanceInMiles: this._convertMetersToMiles(hitItem._rankingInfo.geoDistance),
        openCloseData
      });
    });
  
    const sortedHits = orderBy(newHits, [isOpenNowSelector, 'distanceInMiles'], ['desc', 'asc']);
    
    return sortedHits;
  };
  
  /**
   *
   * @param meters
   * @returns {number}
   * @private
   */
  _convertMetersToMiles = (meters: number) => {
    
    //conversion is 1 meter = 0.000621371192 miles, but we short cut the operation
    return Math.round(((meters || 1) * 0.0621371192)) / 100;
  };
  
  /**
   *
   * @param {RestaurantType} restaurantObject
   * @private
   */
  _generateOpenData = (restaurantObject: RestaurantType): ComputedScheduleDataType => {
    
    const result = getOpenInfoForCurrentTime(restaurantObject);
    
    return result;
  };
  
  
  render() {
    const { hits, hasMore, refine, onHitClick } = this.props;
    const { sortedHits = [] } = this.state;
    
    if(!isEmpty(hits) && isEmpty(sortedHits)) {
      return (
        <div>
          Loading...
        </div>
      );
    }
    
    return (
      <div className={cx('container-fluid')}>
        {sortedHits.map((hit) =>
          <SearchResultItem key={hit._id} restaurantData={hit} onHitClick={() => onHitClick(hit)} />
        )}
        {hasMore && (
          <button disabled={!hasMore} onClick={refine}>
            Show more
          </button>
        )}
      </div>
    );
  }
}

const ConnectedQbHits = connectInfiniteHits(QbInfiniteHits);

export {
  QbInfiniteHits as QbInfiniteHitsComponent,
  ConnectedQbHits as QbInfiniteHits
};
