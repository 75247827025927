import queryString from 'query-string';

import { ACTIONS } from '../ActionTypes';
import { Logger } from '../utils/LoggerService';
import { ORDER_STATUS } from '@foodbuzzer/foodbuzzer-shared/constants';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';

import { getServerUrl } from '../utils/ConfigUtils';

import type { OrderType } from '../types';



/**
 *
 * @param {Object} query
 * @return {function(*)}
 */
export const getFilteredOrders = (query) => {
  return _getManyOrders(query, ACTIONS.GET_FILTERED_ORDERS);
};

/**
 *
 * @param {Object} params
 * @return {function(*)}
 */
export const getManyOrders = (params) => {
  return _getManyOrders(params, ACTIONS.GET_MANY_ORDERS);
};

/**
 *
 * @param {Object} params
 * @param {String} actionType
 * @return {function(*)}
 * @private
 */
let _getManyOrders = (params, actionType) => {
  let newQuery;
  const baseUrl = `${getServerUrl()}/orders`;
  let url = baseUrl;
  
  if(params) {
    let localQueryObject = {};
    Object.keys(params).forEach(item => {
      let itemValue = params[item];
      if(Array.isArray(itemValue)) {
        localQueryObject[item] = itemValue.join(',');
      } else {
        localQueryObject[item] = itemValue;
      }
    });
    
    newQuery = queryString.stringify(localQueryObject);
    
    if(newQuery.length > 0) {
      url = `${baseUrl}?${newQuery}`;
    }
  }
  
  return (dispatch) => {
    
    return ApiFetchUtils.ApiFetch(url).then(body => {
      dispatch({
        type: actionType,
        payload: {
          orders: body
        }
      });
    });
  };
};


export const getOrder = (params) => {
  const {orderId} = params;
  
  const url = `${getServerUrl()}/orders/${orderId}`;
  
  return async (dispatch) => {
    const result = await ApiFetchUtils.ApiFetch(url);
    
    dispatch({
      type: ACTIONS.GET_ORDER,
      payload: {
        order: result
      }
    });
  
    return result;
  };
};

/**
 *
 * @param temporaryOrder
 * @returns {function(*): *}
 */
export const createOrderFromTemporaryOrder = (temporaryOrder: Object) => {

  //const temporaryOrderId = temporaryOrder._id;
  delete temporaryOrder._id;
  
  return (dispatch: any) => {
    return dispatch(postOrder({body: temporaryOrder}));
  };

};

/**
 *
 * @param {{temporaryOrderId: string, groupOrderId?: string}} params
 * @returns {Function}
 */
export const deleteTemporaryOrder = (params: {temporaryOrderId: string, groupOrderId?: string}) => {
  const { temporaryOrderId, groupOrderId = '' } = params;
  return (dispatch: any) => {
  
    dispatch({
      type: ACTIONS.DELETE_TEMPORARY_ORDER,
      payload: {
        orderId: temporaryOrderId,
        groupOrderId
      }
    });
  };
};

/**
 *
 * @param {{body: Object}} params
 * @returns {function(*)}
 */
export const postOrder = (params: {body: Object}) => {
  const { body } = params;
  
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ACTIONS.POST_ORDER_START,
        payload: {
          body: body
        }
      });
  
      const createdOrder = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/orders`, {
        method: 'POST',
        body: body
      });
  
      dispatch({
        type: ACTIONS.POST_ORDER_SUCCESS,
        payload: {
          order: createdOrder
        }
      });
  
      return createdOrder;
    } catch(err) {
      Logger.error('Unexpected error in postOrder', err);
      throw err;
    }
  };
};

/**
 *
 * @param params
 * @returns {function(*)}
 */
export const putOrder = (params: Object) => {
  const { orderId, body } = params;
  
  return async (dispatch) => {
    dispatch({
      type: ACTIONS.PUT_ORDER_START,
      payload: {
        body: body
      }
    });
    
    const updatedOrder = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/orders/${orderId}`, {
      method: 'PUT',
      body: body
    });
    
    dispatch({
      type: ACTIONS.PUT_ORDER_SUCCESS,
      payload: {
        order: updatedOrder
      }
    });
    
    return updatedOrder;
  };
};

/**
 *
 * @param {{order: OrderType}} params
 * @returns {*}
 */
export const cancelOrder = (params: {order: OrderType}) => {
  return module.exports._transitionOrder(params, ORDER_STATUS.CANCELLED);
};

/**
 *
 * @param {{order: OrderType}} params
 * @param {string} transitionToStatus
 * @returns {function(*)}
 * @private
 */
export const _transitionOrder = (params: {order: OrderType}, transitionToStatus: string) => {
  const { order } = params;
  
  return async (dispatch: any): Promise<OrderType> => {
    
    const transitioningOrder = {
      ...order,
      status: transitionToStatus
    };
    
    const transitionedOrder = await dispatch(module.exports.putOrder({orderId: transitioningOrder._id, body: transitioningOrder}));
    
    return transitionedOrder;
  };
};


export const clearSearchedOrders = () => {
  return (dispatch) => {
    return dispatch({
      type: ACTIONS.CLEAR_SEARCHED_ORDERS
    });
  };
};
