import React, { lazy, Suspense } from 'react';

const Checkout = lazy(() => import('./Checkout').then(module => ({ default: module.Checkout })));

const LoadingCheckout = () => {
  
  return <div>Loading...</div>
};

const SuspensedCheckout = (props) => {
  
  return (
    <Suspense fallback={<LoadingCheckout />}>
      <Checkout {...props} />
    </Suspense>
  );
};

export { SuspensedCheckout as Checkout };
