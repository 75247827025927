import React, { lazy, Suspense } from 'react';


const Auth = lazy(() => import('./Auth').then(module => ({ default: module.Auth })));

const LoadingAuth = () => {
  
  return <div>Loading...</div>
};

const SuspensedAuth = (props) => {
  
  return (
    <Suspense fallback={<LoadingAuth />}>
      <Auth {...props} />
    </Suspense>
  )
};

export { SuspensedAuth as Auth };
export { AuthCallback } from './AuthCallback';
