//@flow
import queryString from 'query-string';

import { ACTIONS } from '../ActionTypes';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';
import { getServerUrl } from '../utils/ConfigUtils';
import type { MenuItemType } from '../types';



/**
 *
 * @param {Object} query
 * @return {function(*)}
 */
export const getFilteredMenuItems = (query: Object) => {
  return _getManyMenuItems(query, ACTIONS.GET_FILTERED_MENU_ITEMS);
};

/**
 *
 * @param {Object} params
 * @return {function(*)}
 */
export const getManyMenuItems = (params: Object) => {
  return _getManyMenuItems(params, ACTIONS.GET_MANY_MENU_ITEMS);
};

/**
 *
 * @param {Object} params
 * @param {String} actionType
 * @return {function(*)}
 * @private
 */
let _getManyMenuItems = (params, actionType) => {
  let newQuery;
  const baseUrl = `${getServerUrl()}/menuitems`;
  let url = baseUrl;
  
  if(params) {
    let localQueryObject = {};
    Object.keys(params).forEach(item => {
      let itemValue = params[item];
      if(Array.isArray(itemValue)) {
        localQueryObject[item] = itemValue.join(',');
      } else {
        localQueryObject[item] = itemValue;
      }
    });
    
    newQuery = queryString.stringify(localQueryObject);
    
    if(newQuery.length > 0) {
      url = `${baseUrl}?${newQuery}`;
    }
  }
  
  return async (dispatch: any) => {
    try {
      const retrievedMenuItems = await ApiFetchUtils.ApiFetch(url);
      
      dispatch({
        type: actionType,
        payload: {
          menuItems: retrievedMenuItems
        }
      });
  
      return retrievedMenuItems;
    } catch(err) {
      console.log(`_getManyMenuItems error. [Message: ${err.message}]`)
    }
    
  };
};

/**
 *
 * @param {{menuItemId: string}} params
 * @returns {function(*)}
 */
export const getMenuItem = (params: {menuItemId: string}) => {
  const { menuItemId } = params;
  
  const url = `${getServerUrl()}/menuitems/${menuItemId}`;
  
  return async (dispatch: any) => {
    try {
      const retrievedMenuItem = await ApiFetchUtils.ApiFetch(url);
  
      dispatch({
        type: ACTIONS.GET_MENU_ITEM,
        payload: {
          menuItem: retrievedMenuItem
        }
      });
      
      return retrievedMenuItem;
    } catch(err) {
      console.log(`getMenuItem error. [Message: ${err.message}]`);
    }
  };
};

/**
 *
 * @param {{body: MenuItemType}} params
 * @returns {function(*)}
 */
export const postMenuItem = (params: {body: MenuItemType}) => {
  const { body } = params;
  
  return async (dispatch: any) => {
    dispatch({
      type: ACTIONS.POST_MENU_ITEM_START,
      payload: {
        menuItem: body,
      },
    });
    
    const createdMenuItem = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/menuitems`, {
      method: 'POST',
      body: body,
    });
    
    dispatch({
      type: ACTIONS.POST_MENU_ITEM_SUCCESS,
      payload: {
        menuItem: createdMenuItem,
      },
    });
    
    return createdMenuItem;
  };
};

/**
 *
 * @param {{menuItemId: string, body: Object}} params
 * @returns {function(*)}
 */
export const putMenuItem = (params: {menuItemId: string, body: Object}) => {
  const { menuItemId, body } = params;
  
  return async (dispatch: any) => {
    dispatch({
      type: ACTIONS.PUT_MENU_ITEM_START,
      payload: {
        menuItem: body,
      },
    });
    
    const updatedMenuItem = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/menuitems/${menuItemId}`, {
      method: 'PUT',
      body: body,
    });
    
    dispatch({
      type: ACTIONS.PUT_MENU_ITEM_SUCCESS,
      payload: {
        menuItem: updatedMenuItem,
      },
    });
    
    return updatedMenuItem;
  };
};
