import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch as fasSearch,
  faSignInAlt as fasSignInAlt,
  faShoppingCart as fasShoppingCart,
  faCircle as fasCircle,
  faCheckCircle as fasCheckCircle,
  faDotCircle as fasDotCircle,
  faPlusCircle as fasPlusCircle,
  faMinusCircle as fasMinusCircle,
  faSquare as fasSquare,
  faCheckSquare as fasCheckSquare
} from '@fortawesome/pro-solid-svg-icons';
import {
  faSearch as farSearch,
  faSignInAlt as farSignInAlt,
  faShoppingCart as farShoppingCart,
  faCircle as farCircle,
  faCheckCircle as farCheckCircle,
  faDotCircle as farDotCircle,
  faPlusCircle as farPlusCircle,
  faMinusCircle as farMinusCircle,
  faSquare as farSquare,
  faCheckSquare as farCheckSquare
} from '@fortawesome/pro-regular-svg-icons';
import {
  faSearch as falSearch,
  faSignInAlt as falSignInAlt,
  faShoppingCart as falShoppingCart,
  faCircle as falCircle,
  faCheckCircle as falCheckCircle,
  faDotCircle as falDotCircle,
  faPlusCircle as falPlusCircle,
  faMinusCircle as falMinusCircle,
  faSquare as falSquare,
  faCheckSquare as falCheckSquare,
  faHome as falHome,
  faFile as falFile,
  faFileAlt as falFileAlt,
  faSave as falSave,
  faSpinner as falSpinner,
  faPlus as falPlus
} from '@fortawesome/pro-light-svg-icons';


// const iconsToLoad = [
//   'search',
//   'sign-in-alt',
//   'shopping-cart',
//   'circle',
//   'check-circle',
//   'dot-circle',
//   'plus-circle',
//   'minus-circle',
//   'square',
//   'check-square'
// ];
//



export const loadIcons = () => {
  
  const iconDefinitionsToLoad = [
    fasSearch, farSearch, falSearch,
    fasSignInAlt, farSignInAlt, falSignInAlt,
    fasShoppingCart, farShoppingCart, falShoppingCart,
    fasCircle, farCircle, falCircle,
    fasCheckCircle, farCheckCircle, falCheckCircle,
    fasDotCircle, farDotCircle, falDotCircle,
    fasPlusCircle, farPlusCircle, falPlusCircle,
    fasMinusCircle, farMinusCircle, falMinusCircle,
    fasSquare, farSquare, falSquare,
    fasCheckSquare, farCheckSquare, falCheckSquare,
    falHome,
    falFile,
    falFileAlt,
    falSave,
    falSpinner,
    falPlus
  ];

  library.add(iconDefinitionsToLoad);
};
