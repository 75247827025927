import React, { lazy, Suspense } from 'react';

const RestaurantMenu = lazy(() => import('./RestaurantMenu').then(module => ({ default: module.RestaurantMenu })));

const LoadingRestaurantMenu = () => {
  
  return <div>Loading...</div>
};

const SuspensedRestaurantMenu = (props) => {

  return (
    <Suspense fallback={<LoadingRestaurantMenu />}>
      <RestaurantMenu {...props} />
    </Suspense>
  );
};

export { SuspensedRestaurantMenu as RestaurantMenu };
