//@flow
import React from 'react';
import { constructCloudinaryUrl } from '../utils/ImageUtils';

type ImageProps = {
  src: string;
  height?: number;
  width?: number;
  alt?: string;
  className?: string;
  crop?: string;
  dpr?: string;
}

const Image = React.memo<ImageProps>((props: ImageProps) => {
  const { src = '', height = 50, width = 50, alt = '', className = '', crop = '', dpr = 'auto' } = props;

  const computedSource = constructCloudinaryUrl(src, {dpr: dpr, h: height, w: width, crop});
  
  return (<img src={computedSource} className={className} alt={alt} />);
});

export { Image };
