
import { ACTIONS } from '../ActionTypes';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';
import { Logger } from '../utils/LoggerService';
import { getServerUrl } from '../utils/ConfigUtils';

/**
 *
 * @param {string} userProfileId
 * @returns {Function}
 */
export const getPaymentMethodsForCustomer = (userProfileId: string) => {
  
  return async (dispatch: any) => {
    try {
      const url = `${getServerUrl()}/paymentmethods?userProfileId=${userProfileId}`;
  
      const retrievedPaymentMethods = await ApiFetchUtils.ApiFetch(url);
      dispatch({
        type: ACTIONS.GET_PAYMENT_METHODS_FOR_USER,
        payload: {
          userProfileId: userProfileId,
          paymentMethods: retrievedPaymentMethods
        }
      });
  
      return retrievedPaymentMethods;
    } catch(error) {
      Logger.error(`Unexpected error in getPaymentMethodsForCustomer. [ErrorType: ${error.name}] [Message: ${error.message}]`);
    }
  };
};

/**
 *
 * @param {{userProfileId: string, paymentMethodSource: Object}} params
 * @returns {Function}
 */
export const addPaymentMethod = (params: {userProfileId: string, paymentMethodSource: Object}) => {
  const { userProfileId, paymentMethodSource } = params;
  
  return async (dispatch: any) => {
    try {
      const url = `${getServerUrl()}/paymentmethods`;
  
      const fetchOptions = {
        method: 'POST',
        body: {
          userProfileId,
          paymentMethodSource
        }
      };
  
      const createdPaymentMethod = await ApiFetchUtils.ApiFetch(url, fetchOptions);
  
      dispatch({
        type: ACTIONS.ADD_PAYMENT_METHOD,
        payload: {
          userProfileId: userProfileId,
          paymentMethod: createdPaymentMethod
        }
      });
  
      return createdPaymentMethod;
    } catch(error) {
      Logger.error(`Unexpected error in addPaymentMethod. [ErrorType: ${error.name}] [Message: ${error.message}]`);
    }
  };
  
};

/**
 *
 * @param params
 * @return {Function}
 */
export const removePaymentMethod = (params: {userProfileId: string, paymentMethodSourceId: string}) => {
  const { userProfileId, paymentMethodSourceId } = params;
  
  return async (dispatch: any) => {
    try {
      const url = `${getServerUrl()}/paymentmethods/${paymentMethodSourceId}`;
      
      const fetchOptions = {
        method: 'PUT',
        body: {
          action: 'REMOVE',
          userProfileId,
          paymentMethodSource: paymentMethodSourceId
        }
      };
      
      await ApiFetchUtils.ApiFetch(url, fetchOptions);
      
      dispatch({
        type: ACTIONS.REMOVE_PAYMENT_METHOD,
        payload: {
          userProfileId: userProfileId,
          paymentMethodSourceId: paymentMethodSourceId
        }
      });
      
      return {};
    } catch(error) {
      Logger.error(`Unexpected error in removePaymentMethod. [ErrorType: ${error.name}] [Message: ${error.message}]`);
    }
  };
};
