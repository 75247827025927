//@flow

import React, { lazy, Suspense } from 'react';

const EditMenuItem = lazy(() => import('./EditMenuItem').then(module => ({ default: module.EditMenuItem })));

const LoadingEditMenuItem = () => {
  
  return <div>Loading...</div>
};

const SuspensedEditMenuItem = (props: any) => {
  
  return (
    <Suspense fallback={<LoadingEditMenuItem />}>
      <EditMenuItem {...props} />
    </Suspense>
  );
};

export { SuspensedEditMenuItem as EditMenuItem };
