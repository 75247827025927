//@flow
import { ACTIONS } from '../ActionTypes';
import type { ActionType, CouponsStateType } from '../types';

const getInitialState = (): CouponsStateType => {
  
  return {
    byCode: {}
  };
};

const CouponReducer = (state: CouponsStateType = getInitialState(), action: ActionType) => {

  let newByCode;
  switch(action.type) {
    
    case ACTIONS.GET_COUPON: {
      const { coupon } = action.payload;
      
      newByCode = { ...state.byCode };
      
      newByCode[coupon.code] = coupon;
      
      return {
        ...state,
        byCode: newByCode
      };
    }
    default:
      return state;
  }
};

export { CouponReducer };
