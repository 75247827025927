//@flow
import { get, isEmpty } from 'lodash';


type CloudinaryOptions = {
  w?: number;
  h?: number;
  crop?: string;
  q?: string;
  f?: string;
  dpr?: string;
  tintPercentage?: number;
  tintColor?: string;
}

/**
 *
 * @param {string} imageUrl
 * @param {CloudinaryOptions} options
 * @returns {string}
 */
export const constructCloudinaryUrl = (imageUrl: string, options: CloudinaryOptions = {}) => {
  
  //replaces all http with https images
  let newImageUrl = imageUrl.replace('http:', 'https:');
  
  const replacementString = [];
  
  if(get(options, 'w', '')) {
    replacementString.push(`w_${String(options.w)}`);
  }
  if(get(options, 'h', '')) {
    replacementString.push(`h_${String(options.h)}`);
  }
  
  const parsedCrop = get(options, 'crop', '');
  if(parsedCrop) {
    replacementString.push(`c_${parsedCrop}`);
  }
  
  const parsedQuality = get(options, 'q', '');
  if(parsedQuality) {
    replacementString.push(`q_${parsedQuality}`)
  } else {
    replacementString.push('q_auto');
  }
  
  let dprSetting = get(options, 'dpr', '3.0');
  if (dprSetting !== '1.0' && dprSetting !== '2.0' && dprSetting !== '3.0' && dprSetting !== 'auto') {
    dprSetting = '3.0';
  }
  if (dprSetting) {
    replacementString.push(`dpr_${dprSetting}`);
  }
  
  const parsedFocus = get(options, 'f', '');
  if(parsedFocus) {
    replacementString.push(`f_${parsedFocus}`);
  } else {
    replacementString.push('f_auto');
  }
  
  const parsedTintPercentage = get(options, 'tintPercentage', 0);
  const parsedTintColor = get(options, 'tintColor', '');
  
  if(parsedTintPercentage > 0 && !isEmpty(parsedTintColor)) {
    //sample e_tint:25:rgb:3e2222
    replacementString.push(`e_tint:${parsedTintPercentage}:${parsedTintColor}`)
  }
  
  
  if(replacementString.length > 0) {
    newImageUrl = newImageUrl.replace(/\/v\d+\//i, `/${replacementString.join(',')}/`);
  }
  
  return newImageUrl;
};

export const IMAGES = {
  BREAKFAST: {
    ONE: 'https://res.cloudinary.com/quickbite-prod/image/upload/v1550778429/Splash/Breakfast_1.jpg'
  },
  LUNCH: {
    ONE: 'https://res.cloudinary.com/quickbite-prod/image/upload/v1550778493/Splash/Lunch_1.jpg'
  }
};

export const LOGOS = {
  WHITE: 'https://res.cloudinary.com/quickbite-prod/image/upload/v1550784381/Logo/White_Logo',
  BLACK: 'https://res.cloudinary.com/quickbite-prod/image/upload/v1550784122/Logo/Black_Logo'
};
