//@flow
import { ACTIONS } from '../ActionTypes';
import { AuthService } from '../services/AuthService';
import type { ActionType, AuthStateType } from '../types';


const getNewAuthState = (): AuthStateType => {
  return {
    isAuthenticated: AuthService.isLoggedIn(),
    isAdmin: AuthService.isAdmin(),
    loggedInUser: {},
    loggedInUserId: AuthService.getUserId(),
    idToken: AuthService.getToken(),
    isLoaded: false,
    errorMessage: null,
    expirationTime: AuthService.getTokenExpiration()
  };
};

const AuthReducer = (state: AuthStateType = getNewAuthState(), action: ActionType) => {
  switch (action.type) {
    case ACTIONS.AUTH_INITIALIZED: {
      const { isAuthenticated, isAdmin, loggedInUserId, idToken, loggedInUser, expirationTime} = action.payload;
      
      return {
        ...state,
        isAuthenticated,
        isAdmin,
        loggedInUserId,
        loggedInUser,
        idToken,
        isLoaded: true,
        expirationTime: expirationTime
      };
    }
    case ACTIONS.LOGIN_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errorMessage: null
      };
    }
    case ACTIONS.CREDENTIALS_REFRESHED: {
      
      return {
        ...state,
        idToken: action.payload.idToken,
        expirationTime: action.payload.expirationTime
      };
    }
    case ACTIONS.LOGIN_SUCCESS:
      const { userProfile, loggedInUserId, isAdmin, idToken, expirationTime } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: isAdmin,
        loggedInUser: userProfile,
        loggedInUserId: loggedInUserId,
        idToken: idToken,
        expirationTime: expirationTime
      };
    case ACTIONS.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        loggedInUser: {},
        loggedInUserId: null,
        idToken: null,
        errorMessage: null,
        expirationTime: -1
      };
    case ACTIONS.STORE_DEVICE_TOKEN: {
      
      return {
        ...state,
        deviceToken: action.payload.deviceToken
      };
    }
    case ACTIONS.UPDATE_USER_PROFILE_DEVICE_INFO: {
      
      return {
        ...state,
        loggedInUser: action.payload.userProfile
      };
    }
    
    case ACTIONS.GET_LOGGED_IN_USER_PROFILE:
    case ACTIONS.START_USER_PROFILE_UPDATE:
    case ACTIONS.USER_PROFILE_UPDATED: {
      const { loggedInUser, loggedInUserId} = action.payload;
      
      return {
        ...state,
        loggedInUser: loggedInUser,
        loggedInUserId: loggedInUserId,
      };
    }
    case ACTIONS.START_USER_PROFILE_PATCH:
    case ACTIONS.SUCCESS_USER_PROFILE_PATCH: {
      const { loggedInUser, loggedInUserId} = action.payload;
    
      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          ...loggedInUser
        },
        loggedInUserId: loggedInUserId,
      };
    }
    case ACTIONS.LOGIN_ERROR: {
      
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        errorMessage: action.payload.errorMessage
      }
    }
    default:
      return state;
  }
};

export { AuthReducer };
