import { ACTIONS } from '../ActionTypes';
import uuid from 'uuid';

/**
 *
 * @param {{orderId: string}} params
 * @returns {function(*)}
 * @constructor
 */
export const setActiveTemporaryOrder = (params: {orderId: string}) => {
  const { orderId } = params;
  
  
  return (dispatch) => {
    
    dispatch({
      type: ACTIONS.SET_ACTIVE_TEMPORARY_ORDER,
      payload: {
        activeOrderId: orderId
      }
    });
  };
};

/**
 *
 * @param {{userProfileId: string, restaurantId: string, groupOrderId?: string}} params
 * @returns {function(*)}
 */
export const createTemporaryOrder = (params: {userProfileId: string, restaurantId: string, groupOrderId: string}) => {
  const { userProfileId = '', restaurantId = '', groupOrderId = '' } = params;
  
  return (dispatch: any) => {
  
    const orderId = uuid();
    
    const newOrder = {
      _id: orderId,
      restaurantId,
      userProfileId,
      groupOrderId,
      menuItems: []
    };
    
    dispatch({
      type: ACTIONS.CREATE_TEMPORARY_ORDER,
      payload: {
        order: newOrder
      }
    });
    
    return newOrder;
  };
};

/**
 *
 * @param {{order: Object}} params
 * @returns {function(*)}
 */
export const updateTemporaryOrder = (params: {order: Object}) => {
  const { order } = params;

  
  return (dispatch: any) => {
    
    const updatedOrder = {...order};
    
    dispatch({
      type: ACTIONS.UPDATE_TEMPORARY_ORDER,
      payload: {
        order: updatedOrder
      }
    });
    
    return updatedOrder;
  };
};

export const setCurrentRestaurant = (params: {restaurantId: string}) => {
  
  return (dispatch: any) => {
    
    return dispatch({
      type: ACTIONS.SET_CURRENT_RESTAURANT_ID,
      payload: {
        restaurantId: params.restaurantId
      }
    });
  };
};
