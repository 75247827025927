//@flow
import React, { PureComponent } from 'react';
import './App.css';
import { AppRouter } from '../AppRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { getStripePublishableKey } from '../../utils/ConfigUtils';

type AppProps = {

}

type AppState = {
  stripe: any;
}

class App extends PureComponent<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    
    this.state = {
      stripe: null
    };
  }
  
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(getStripePublishableKey())});
    } else {
      const selector = document.querySelector('#stripe-js');
      if(selector) {
        selector.addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({stripe: window.Stripe(getStripePublishableKey())});
        });
      }
    }
  }
  
  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Router>
          <AppRouter />
        </Router>
      </StripeProvider>
    );
  }
}

export { App };
