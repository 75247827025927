//@flow
import React from 'react';
import { get, has, isEmpty } from 'lodash';
import cns from 'classnames/bind';

import { Image } from '../../components/Image';

import type { RestaurantType } from '../../types';

import styles from './SearchResultItem.module.scss';


const cx = cns.bind(styles);

const DefaultImageSrc = require('../../resources/DefaultFoodTruck/BlankRestaurantImage@2x.png');

type SearchResultItemProps = {
  restaurantData: { ...RestaurantType, distanceInMiles: number, openCloseData: any, restaurantLocationId: string };
  onHitClick: any;
}

const SearchResultItem = (props: SearchResultItemProps) => {
  
  const { restaurantData } = props;
  
  const openRestaurantLocationId = get(restaurantData, 'openCloseData.restaurantLocationId', '') || '';
  const isRestaurantOpenNow = get(restaurantData, 'openCloseData.isOpenNow', false) || false;
  const openCloseDataText = get(restaurantData, 'openCloseData.text', '') || '';
  
  // if restaurant doesn't match the open restaurant, then don't display
  // if restaurant doesn't match, and its closed and is default, then show
  if(has(restaurantData, 'isDefaultLocation')) {
    if ((openRestaurantLocationId !== restaurantData.restaurantLocationId) &&
      !(openRestaurantLocationId !== restaurantData.restaurantLocationId && !isRestaurantOpenNow && has(restaurantData, 'isDefaultLocation') && restaurantData.isDefaultLocation)) {
      return null;
    }
  }
  
  const imageSrc = !isEmpty(restaurantData.showcaseImage) ? restaurantData.showcaseImage : DefaultImageSrc;
  
  console.log(`isRestaurantOpenNow: ${isRestaurantOpenNow}`);
  
  let address1 = '';
  if(restaurantData && restaurantData.address) {
    address1 = restaurantData.address.address1 || '';
  }
  
  return (
    <div className={cx(styles.container)} onClick={props.onHitClick}>
      <div className={styles.imageContainer}>
        <Image src={imageSrc}
               className={styles.image}
               height={125}
               width={125}
               alt={`${restaurantData.name} image`} />
      </div>
      <div className={styles.details}>
        <div className={styles.restaurantName}>{ restaurantData.name }</div>
        <div className={styles.description}>{restaurantData.description}</div>
        <div className={styles.location}>{address1} ({restaurantData.distanceInMiles} Miles)</div>
        <div className={cx({[styles.openTime]: isRestaurantOpenNow}, {[styles.closeTime]: !isRestaurantOpenNow})}>{openCloseDataText}</div>
      </div>
    </div>
  )
};

export { SearchResultItem };
