//@flow
import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import cns from 'classnames/bind';
import { isEmpty, isEqual, orderBy, get, set } from 'lodash';
import moment from 'moment';

import { QuantityInput, TextAreaInput } from '../../components/inputs';
import { Logger } from '../../utils/LoggerService';
import { CostUtils, NumberUtils } from '@foodbuzzer/foodbuzzer-shared/utils';
import { PROMPT_TYPE } from '@foodbuzzer/foodbuzzer-shared/constants';
import { MenuChoiceTitle } from './MenuChoiceTitle';

import { MenuChoiceOption } from './MenuChoiceOption';

import type { MenuItemChoiceOptionType, MenuItemChoiceType, MenuItemType, OrderMenuItemType } from '../../types';

import styles from './AddMenuItemModal.module.scss';


const cx = cns.bind(styles);

/**
 *
 * @param {Object} item
 * @returns {number}
 */
const convertDateToUnixTimestamp = (item) => {
  
  const momentPlacedDateTime = item.addedDateTime ? moment(item.addedDateTime) : moment(0);
  
  return momentPlacedDateTime.unix();
};

type AddMenuItemModalProps = {
  isOpen: boolean;
  isNew: boolean;
  toggle: () => void;
  orderMenuItem: OrderMenuItemType;
  selectedMenuItem: MenuItemType;
  onAddOrUpdateToOrder: (params: any) => Promise<void>;
}

type AddMenuItemModalState = {
  orderMenuItem: OrderMenuItemType;
  missingRequiredChoices: Object;
  isBottom: boolean;
  isTop: boolean;
  errorMessage: string;
}

const DEFAULT_MENU_ITEM_STRING: string = '';

class AddMenuItemModal extends PureComponent<AddMenuItemModalProps, AddMenuItemModalState> {
  
  _specialInstructionsRef: any;
  _inputs: Object = {};
  modalBodyScrollable: any;
  
  constructor(props: AddMenuItemModalProps) {
    super(props);
  
    const orderMenuItem = props.orderMenuItem || {};
    
    this.modalBodyScrollable = React.createRef();
    
    this.state = {
      orderMenuItem: orderMenuItem,
      missingRequiredChoices: {},
      isBottom: false,
      isTop: true, //modal will always start at the top
      errorMessage: ''
    };
  }
  
  componentDidMount() {
    this.setState({
      orderMenuItem: this.props.orderMenuItem || {}
    })
  }
  
  componentDidUpdate(prevProps: AddMenuItemModalProps) {
    if(!isEqual(this.props.orderMenuItem, prevProps.orderMenuItem)) {
      this.setState({
        orderMenuItem: this.props.orderMenuItem
      });
    }
  }
  
  /**
   *
   * @param {string} fieldName
   * @param {*} value
   * @param e
   */
  onFieldChange = (fieldName: string, value: any, e: any) => {
    const { orderMenuItem } = this.state;
    
    const copiedOrderMenuItem = {...orderMenuItem};
    
    set(copiedOrderMenuItem, fieldName, value);
    
    this.setState({orderMenuItem: copiedOrderMenuItem})
  };
  
  /**
   *
   * @returns {*}
   * @private
   */
  _generateMenuItemChoices = () => {
    const { orderMenuItem = {} } = this.state;
    const { menuItem = {}, selectedChoices ={} } = orderMenuItem;
    const { choices = {} } = menuItem;
    const choiceList: MenuItemChoiceType[] = Object.values(choices);
    
    if(isEmpty(choiceList)) {
      return null;
    }
    
    const generatedChoiceList = choiceList.map((choice: MenuItemChoiceType) => {
      
      const generatedChoiceOptionList = choice.options.map((option: MenuItemChoiceOptionType) => {
        
        if(isEmpty(option.name)) {
          Logger.warn(`Option is being skipped due to an empty name. [MenuItemId: ${menuItem._id}] [ChoiceId: ${choice.id}] [OptionId: ${option.id}]`);
          return null;
        }
        
        const isOptionSelected = this._isMenuItemChoiceOptionSelected(selectedChoices, choice, option.id);
        
        let displayPrice = option.price;
        if(option.price <= 0) {
          displayPrice = 'No Charge';
        } else {
          displayPrice = NumberUtils.convertCentsToUSD(option.price);
        }
        
        return (
          <MenuChoiceOption key={option.id}
                            choice={choice}
                            option={option}
                            displayPrice={displayPrice}
                            isOptionSelected={isOptionSelected}
                            onSelectChoiceOption={this._selectChoiceOption} deselectable={!choice.isRequired} />
        );
      });
      
      let subtitleText = '';
      if(choice.promptType === PROMPT_TYPE.CHOOSE_ONE) {
        subtitleText = 'Choose One';
      } else if(choice.promptType === PROMPT_TYPE.CHOOSE_MANY) {
        subtitleText = 'Choose Many';
      } else if(choice.promptType === PROMPT_TYPE.CHOOSE_N_ITEMS) {
        subtitleText = `Choose ${choice.optionLimit}`;
      }
      
      let errorMessage = '';
      if(choice.id in this.state.missingRequiredChoices) {
        errorMessage = 'Required';
      }
      
      
      return (
        <div key={choice.id} ref={(titleRef) => this._inputs[choice.id] = titleRef}>
          <div style={{flexDirection: 'row'}}>
            <MenuChoiceTitle text={choice.prompt} subtitle={subtitleText} isRequired={choice.isRequired} errorMessage={errorMessage}  />
          </div>
          { generatedChoiceOptionList }
        </div>
      );
    });
    
    
    return (
      <Fragment>
        { generatedChoiceList }
      </Fragment>
    );
  };
  
  /**
   *
   * @param {string} promptType
   * @param {string} menuItemChoiceId
   * @param {string} selectedChoiceOptionId
   * @param {number} optionLimit
   * @private
   */
  _selectChoiceOption = (promptType: string, menuItemChoiceId: string, selectedChoiceOptionId: string, optionLimit: number): void => {
    Logger.info('Calling _selectChoiceOption');
    
    const { orderMenuItem } = this.state;
    
    const copiedOrderMenuItem = { ...orderMenuItem };
    
    if(!isEmpty(this.state.missingRequiredChoices) && (menuItemChoiceId in this.state.missingRequiredChoices)) {
      const copiedMissingRequiredChoices = {
        ...this.state.missingRequiredChoices
      };
      
      delete copiedMissingRequiredChoices[menuItemChoiceId];
      
      this.setState({
        missingRequiredChoices: copiedMissingRequiredChoices,
        errorMessage: ''
      });
    }
    
    if(isEmpty(copiedOrderMenuItem.selectedChoices)) {
      copiedOrderMenuItem.selectedChoices = {};
    }
    
    if(promptType === PROMPT_TYPE.CHOOSE_ONE) {
      copiedOrderMenuItem.selectedChoices[menuItemChoiceId] = {
        [selectedChoiceOptionId]: selectedChoiceOptionId
      };
    } else if(promptType === PROMPT_TYPE.CHOOSE_MANY) {
      const selectedChoices = copiedOrderMenuItem.selectedChoices[menuItemChoiceId] || {};
      
      if(!(selectedChoiceOptionId in selectedChoices)) {
        //if it doesnt exist in selectedChoices, we add it
        copiedOrderMenuItem.selectedChoices[menuItemChoiceId] = {
          ...selectedChoices,
          [selectedChoiceOptionId]: selectedChoiceOptionId
        };
      } else {
        //if it DOES exist in selectedChoices, we remove it
        const copiedSelectedChoices = {
          ...selectedChoices
        };
        delete copiedSelectedChoices[selectedChoiceOptionId];
        
        copiedOrderMenuItem.selectedChoices[menuItemChoiceId] = {
          ...copiedSelectedChoices
        };
      }
    } else if(promptType === PROMPT_TYPE.CHOOSE_N_ITEMS) {
      const selectedChoices = copiedOrderMenuItem.selectedChoices[menuItemChoiceId] || {};
      
      if(!(selectedChoiceOptionId in selectedChoices)) {
        const selectedChoiceList = Object.values(selectedChoices || {});
        
        //add it if not at limit
        if(selectedChoiceList.length < optionLimit) {
          copiedOrderMenuItem.selectedChoices[menuItemChoiceId] = {
            ...selectedChoices,
            [selectedChoiceOptionId]: {
              selectedChoiceOptionId,
              addedDateTime: moment().utc().format()
            }
          };
        }
        
        //add it and remove the first one
        if(selectedChoiceList.length >= optionLimit) {
          //remove first added
          const selectedKeys = Object.keys(copiedOrderMenuItem.selectedChoices[menuItemChoiceId]);
          const sortedDefaultValues = orderBy(copiedOrderMenuItem.selectedChoices[menuItemChoiceId], [convertDateToUnixTimestamp], ['asc']);
          
          const copiedSelectedChoices = {
            ...copiedOrderMenuItem.selectedChoices[menuItemChoiceId]
          };
          
          if(sortedDefaultValues[0].selectedChoiceOptionId) {
            delete copiedSelectedChoices[sortedDefaultValues[0].selectedChoiceOptionId];
          } else {
            
            //if the sortedDefaultValues only contain "Bool(true)", then we just remove
            // the first one from the list
            delete copiedSelectedChoices[selectedKeys[0]];
          }
          
          
          copiedOrderMenuItem.selectedChoices[menuItemChoiceId] = {
            ...copiedSelectedChoices,
            [selectedChoiceOptionId]: {
              selectedChoiceOptionId,
              addedDateTime: moment().utc().format()
            }
          };
        }
      } else {
        //if it DOES exist in selectedChoices, we remove it
        const copiedSelectedChoices = {
          ...selectedChoices
        };
        delete copiedSelectedChoices[selectedChoiceOptionId];
        
        copiedOrderMenuItem.selectedChoices[menuItemChoiceId] = {
          ...copiedSelectedChoices
        };
      }
    }
    
    this.setState({
      orderMenuItem: copiedOrderMenuItem
    });
  };
  
  /**
   *
   * @param {Object} selectedChoices
   * @param {MenuItemChoiceType} menuItemChoice
   * @param {string} optionId
   * @private
   */
  _isMenuItemChoiceOptionSelected = (selectedChoices: Object, menuItemChoice: MenuItemChoiceType, optionId: string): boolean => {
    let isSelected = false;
    
    let selectedOption: any = null;
    if(!isEmpty(selectedChoices)) {
      selectedOption = selectedChoices[menuItemChoice.id];
    }
    
    if(!isEmpty(menuItemChoice)) {
      if (menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_ONE ||
        menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_MANY ||
        menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_N_ITEMS) {
        //if promptType is chooseOne, we expect selectedOption to be an object
        const isOptionSelected = (!isEmpty(selectedOption) && (optionId in selectedOption));
        
        if (isOptionSelected) {
          isSelected = true;
        }
      }
    }
    
    return isSelected;
  };
  
  /**
   *
   * @param {number} newValue
   */
  handleQuantityChange = (newValue: number): void => {
    let computedQuantity = newValue;
    if(computedQuantity <= 0 || isNaN(computedQuantity)) {
      computedQuantity = 1;
    }
    
    const { orderMenuItem } = this.state;
    const copiedOrderMenuItem = { ...orderMenuItem };
    
    copiedOrderMenuItem.quantity = computedQuantity;
    
    this.setState({
      orderMenuItem: copiedOrderMenuItem
    });
  };
  
  handleScroll = (e: any) => {
    const newState = {
      isBottom: false,
      isTop: false
    };
  
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    
    if(bottom) {
      newState.isBottom = true;
    }
    
    const top = e.target.scrollTop === 0;
    if(top) {
      newState.isTop = true;
    }
    
    this.setState(newState);
  };
  
  _scrollToElement = (ref: any) => {
  
    //scroll to ref
    this.modalBodyScrollable.scrollTop = ref.offsetTop;
  };
  
  handleAddItemToOrder = async () => {
    try {
      const orderMenuItem: OrderMenuItemType = this.state.orderMenuItem;
    
    
      //verify all required items have a selection
      const choices = get(orderMenuItem, 'menuItem.choices', {});
      if(!isEmpty(choices)) {
        const { emptyChoices, firstMissingChoiceId } = this._getEmptyChoices(orderMenuItem, choices);
      
        if(!isEmpty(emptyChoices)) {
          this.setState({
            missingRequiredChoices: emptyChoices,
            errorMessage: 'Missing Required fields'
          });
          this._scrollToElement(this._inputs[firstMissingChoiceId]);
          return;
        }
      }
    
      if (this.props.onAddOrUpdateToOrder) {
      
        const copiedOrderMenuItem = {
          ...orderMenuItem
        };
      
        if(isEmpty(copiedOrderMenuItem.selectedChoices)) {
          copiedOrderMenuItem.selectedChoices = {};
        }
      
        const params = {
          orderMenuItem: copiedOrderMenuItem
        };
      
        await this.props.onAddOrUpdateToOrder(params);
      }
    
      this.props.toggle();
    } catch(error) {
      Logger.error(`Unexpected error _onAddOrUpdateToOrder. [Message: ${error.message}]`);
    }
  };
  
  /**
   *
   * @param {OrderMenuItemType} orderMenuItem
   * @param {Object} choices
   * @returns {{emptyChoices: Object, firstMissingChoiceId: string}}
   * @private
   */
  _getEmptyChoices = (orderMenuItem: OrderMenuItemType, choices: Object): {emptyChoices: Object, firstMissingChoiceId: string } => {
    
    const emptyChoices = {};
    let firstMissingChoiceId: string = '';
    
    const menuItemChoices: Array<MenuItemChoiceType> = Object.values(choices);
    const choiceLength = menuItemChoices.length;
    let menuItemChoice: MenuItemChoiceType;
    for (let choiceIndex = 0; choiceIndex <= choiceLength; choiceIndex++) {
      menuItemChoice = menuItemChoices[choiceIndex];
      
      if(isEmpty(menuItemChoice)) {
        continue;
      }
      
      if (menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_ONE && get(menuItemChoice, 'isRequired', false) === true) {
        if(isEmpty(orderMenuItem.selectedChoices[menuItemChoice.id])) {
          
          if(isEmpty(firstMissingChoiceId)) {
            firstMissingChoiceId = menuItemChoice.id;
          }
          
          emptyChoices[menuItemChoice.id] = true;
        }
        
      }
    }
    return {
      emptyChoices,
      firstMissingChoiceId
    };
  };
  
  
  render() {
    const { orderMenuItem } = this.state;
    
    const computedCost = CostUtils.calculateCostWithoutTaxForOrderMenuItem(orderMenuItem);
    const formattedComputedCost = NumberUtils.convertCentsToUSD(computedCost);
    
    const menuItemName = get(orderMenuItem, 'menuItem.name' ,'');
    const menuItemDescription = get(orderMenuItem, 'menuItem.description', '');
  
    let buttonText = 'Add to Order';
    if(!this.props.isNew) {
      buttonText = 'Update Item';
    }
    
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} contentClassName={styles.modalContent} centered={true}>
        <ModalHeader toggle={this.props.toggle} className={cx({[styles.modalHeaderScrollable]: !this.state.isTop})}>
          <div className={cx(styles.title)}>{menuItemName || DEFAULT_MENU_ITEM_STRING}</div>
          <div className={cx(styles.description)}>{menuItemDescription || DEFAULT_MENU_ITEM_STRING}</div>
        </ModalHeader>
        <div className={cx('modal-body', styles.modalBody)} onScroll={this.handleScroll} ref={(ref) => this.modalBodyScrollable = ref}>
          {this._generateMenuItemChoices()}
          
          <div ref={(specialInstructions) => this._specialInstructionsRef = specialInstructions} className={styles.specialInstructions}>
            <TextAreaInput label={'Special Instructions'} fieldName={'notes'} onChange={this.onFieldChange} />
          </div>
        </div>
        <ModalFooter className={cx({[styles.modalFooterScrollable]: !this.state.isBottom})}>
          {!isEmpty(this.state.errorMessage) && <div className={styles.errorMessage}>{this.state.errorMessage}</div> }
          <QuantityInput containerClassName={styles.quantity} value={orderMenuItem.quantity} onChange={this.handleQuantityChange}/>
          <Button color="primary" onClick={this.handleAddItemToOrder}>{buttonText} ({formattedComputedCost})</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export { AddMenuItemModal };
