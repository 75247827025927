//@flow


/**
 *
 * @remarks if split by period (.), second item should be "order", if not, return empty string
 * @param {string} hostname
 * @returns {string}
 */
export const getSubdomainFromHostName = (hostname: string): string => {
  const results = (hostname || '').split('.');
  
  if(results[1] === 'order') {
    return results[0];
  } else {
    return '';
  }
};
