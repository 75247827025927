import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink, Link } from 'react-router-dom';
import {
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cns from 'classnames/bind';

import { Image } from '../../components/Image';
import type { StateType } from '../../types';

import styles from './Header.module.scss';
import { logout } from '../../actions';
import { LOGOS } from '../../utils/ImageUtils';

const cx = cns.bind(styles);

type HeaderProps = {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
}

type HeaderState = {
  isOpen: boolean;
}

class Header extends PureComponent<HeaderProps, HeaderState> {
  
  static mapStateToProps = (state: StateType) => {
    const { auth } = state;
    
    
    return {
      isAuthenticated: auth.isAuthenticated,
      isAdmin: auth.isAdmin
    };
  };
  
  static mapDispatchToProps = (dispatch: any) => {
    
    return {
      logout: () => dispatch(logout())
    }
  };
  
  constructor(props: HeaderProps) {
    super(props);
    
    this.state = {
      isOpen: false
    }
  }
  
  
  toggle = () => {
  
    this.setState((state: HeaderState, props) => {
      return {isOpen: !state.isOpen};
    });
  };
  
  _navigateToProfile = async () => {
  
    this.props.history.push('/profile');
  };
  
  _logout = async () => {
  
    await this.props.logout();
    this.props.history.push('/');
  };
  
  render() {
    
    return (
      <Navbar className={cx('fixed-top', styles.navbar)} dark={true} expand={'md'}>
        <NavbarBrand tag={Link} to='/'>
          <Image className={styles.brandImage} src={LOGOS.WHITE} width={137} height={40} crop={'fit'} dpr={'3.0'} alt={'QuickBite Logo'} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar={true} className={styles.navbarCollapse}>
          <Nav className="ml-auto" navbar={true}>
            {/*<NavItem className={styles.navItem}>*/}
              {/*<NavLink to='/search'>*/}
                {/*<FontAwesomeIcon icon={['fal','search']} fixedWidth={true} className={styles.navItemIcon} />*/}
                {/*Search*/}
              {/*</NavLink>*/}
            {/*</NavItem>*/}
            {!this.props.isAuthenticated && (
              <NavItem className={styles.navItem}>
                <NavLink to={'/login'}>
                  Sign Up / Sign In
                </NavLink>
              </NavItem>
            )}
            {(this.props.isAuthenticated && this.props.isAdmin) && (
              <NavItem className={styles.navItem}>
                <NavLink to={'/admin'}>
                  Admin Portal
                </NavLink>
              </NavItem>
            )}
            {this.props.isAuthenticated && (
              <UncontrolledDropdown className={styles.navItem} nav={true} inNavbar={true}>
                <DropdownToggle className={styles.dropdown} nav={true} caret={true}>
                  Account
                </DropdownToggle>
                <DropdownMenu right={true}>
                  <DropdownItem onClick={this._navigateToProfile}>
                    My Profile
                  </DropdownItem>
                  <DropdownItem divider={true} />
                  <DropdownItem onClick={this._logout}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const withRouterHeader = withRouter(connect(Header.mapStateToProps, Header.mapDispatchToProps)(Header));

export {
  withRouterHeader as Header
};
