//@flow
import { ACTIONS } from '../ActionTypes';
import type { ActionType, MenuItemsStateType } from '../types';

const getInitialState = (): MenuItemsStateType => {
  return {
    byId: {},
    byRestaurantId: {},
    filteredById: {}
  };
};


export default (state: MenuItemsStateType = getInitialState(), action: ActionType) => {
  let newById;
  let newByRestaurantId;
  switch (action.type) {
    case ACTIONS.GET_MANY_MENU_ITEMS: {
      newById = {...state.byId};
      newByRestaurantId = {...state.byRestaurantId};
      action.payload.menuItems.forEach(menuItem => {
        newById[menuItem._id] = menuItem;
        
        if(!(menuItem.restaurantId in newByRestaurantId)) {
          newByRestaurantId[menuItem.restaurantId] = {};
        }
        
        newByRestaurantId[menuItem.restaurantId][menuItem._id] = menuItem;
      });
      
      return {
        ...state,
        byId: newById,
        byRestaurantId: newByRestaurantId
      };
    }
    
    case ACTIONS.GET_FILTERED_MENU_ITEMS: {
      newById = {};
      newByRestaurantId = {...state.byRestaurantId};
      action.payload.menuItems.forEach(menuItem => {
        newById[menuItem._id] = menuItem;
        
        if(!(menuItem.restaurantId in newByRestaurantId)) {
          newByRestaurantId[menuItem.restaurantId] = {};
        }
        
        newByRestaurantId[menuItem.restaurantId][menuItem._id] = menuItem;
      });
      
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById
        },
        byRestaurantId: newByRestaurantId,
        filteredById: newById
      };
    }
    
    case ACTIONS.POST_MENU_ITEM_SUCCESS:
    case ACTIONS.PUT_MENU_ITEM_SUCCESS:
    case ACTIONS.GET_MENU_ITEM: {
      const { menuItem } = action.payload;
      newById = {
        ...state.byId,
        [menuItem._id]: menuItem
      };
      
      newByRestaurantId = { ...state.byRestaurantId };
      
      if(!(menuItem.restaurantId in newByRestaurantId)) {
        newByRestaurantId[menuItem.restaurantId] = {};
      }
      newByRestaurantId[menuItem.restaurantId][menuItem._id] = menuItem;
      
      return {
        ...state,
        byId: newById,
        byRestaurantId: newByRestaurantId
      };
    }
    
    case ACTIONS.CLEAR_SEARCHED_MENU_ITEMS: {
      return {
        ...state,
        filteredById: {}
      };
    }
    
    default:
      return state;
  }
};
