//@flow
import { createSelector } from 'reselect';
import { isEmpty, orderBy } from 'lodash';

import type { MenuItemType, StateType } from '../types';

export const makeGetSortedMenuItemsByRestaurantForTakeout = (): (state: StateType, restaurantId: string, dayOfWeek: string) => MenuItemType[] => {
  return createSelector(
    (state: StateType) => state.menuItems.byRestaurantId,
    (_, restaurantId: string) => restaurantId,
    (_, __, dayOfWeek: string) => dayOfWeek,
    (menuItemsByRestaurant: {[string]: MenuItemType[]}, restaurantId: string, dayOfWeek: string): MenuItemType[] => {
      if(!(restaurantId in menuItemsByRestaurant)) {
        return [];
      }
      
      const menuItems = Object.values(menuItemsByRestaurant[restaurantId]);
      
      const filteredMenuItems = menuItems.filter((menuItem: MenuItemType) => {
  
        const isVisible = (menuItem.isVisible !== false);
        const isAvailable = (menuItem.availability !== 'deliveryonly');
  
        let isLimitedByDays = false;
        if(menuItem.hasLimitedDays === true && !isEmpty(dayOfWeek)) {
          const dayLength = (menuItem.limitedDays || []).length;
          let dayItem;
          isLimitedByDays = true;
          for(let index = 0; index < dayLength; index++) {
            dayItem = menuItem.limitedDays[index];
            if(dayItem === dayOfWeek) {
              isLimitedByDays = false;
              break;
            }
          }
        }
        
        return isVisible && isAvailable && !isLimitedByDays;
      });
      
      return orderBy(filteredMenuItems, ['sortOrder', '_id'], ['asc', 'asc']);
    }
  );
};
