//@flow
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import cns from 'classnames/bind';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import anime from 'animejs'
import { Transition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';

import { makeGetTemporaryOrderForPickup } from '../../selectors';

import styles from './OrderBar.module.scss';
import type { OrderCostType, OrderMenuItemType, OrderType, StateType } from '../../types';
import * as CostUtils from '@foodbuzzer/foodbuzzer-shared/utils/CostUtils';
import * as NumberUtils from '@foodbuzzer/foodbuzzer-shared/utils/NumberUtils';
import { CheckoutMenuItem } from '../../components/CheckoutMenuItem';
import { deleteTemporaryOrder, updateTemporaryOrder } from '../../actions';
import { Logger } from '../../utils/LoggerService';
import { findIndex, isEmpty } from 'lodash';
import { AddMenuItemModal } from '../AddMenuItemModal';
import { QbButton } from '../../components/QbButton';


const cx = cns.bind(styles);

const createOpacityAnimationConfig = animatingIn => ({
  value: animatingIn ? [0, 1] : 0,
  easing: 'linear',
  duration: 300
});

const easing = 'linear';//'easeInElastic(1, 1)';

type OrderBarProps = {
  history: any;
  location: {pathname: string};
  currentTemporaryOrder: OrderType;
  updateTemporaryOrder: (params: {order: Object}) => Promise<OrderType>;
  createOrderFromTemporaryOrder: (temporaryOrder: Object) => Promise<OrderType>;
  deleteTemporaryOrder: ({temporaryOrderId: string}) => Promise<void>;
}

type OrderBarState = {
  showDrawer: boolean;
  selectedOrderMenuItem: OrderMenuItemType;
  isMenuItemModalOpen: boolean;
  isTop: boolean;
  isBottom: boolean;
}

class OrderBar extends PureComponent<OrderBarProps, OrderBarState> {
  
  static mapStateToProps = () => {
  
    const getTemporaryOrderForPickup = makeGetTemporaryOrderForPickup();
  
    const derivedMapStateToProps = (state: StateType) => {
      const { incompleteOrders} = state;
  
      const currentTemporaryOrder = getTemporaryOrderForPickup(state, incompleteOrders.restaurantId);
      
      return {
        currentTemporaryOrder
      };
    };
    
    return derivedMapStateToProps;
  };
  
  static mapDispatchToProps = (dispatch: any) => {
    
    return {
      updateTemporaryOrder: (params: Object) => dispatch(updateTemporaryOrder(params)),
      deleteTemporaryOrder: (params: Object) => dispatch(deleteTemporaryOrder(params)),
    };
  };
  
  constructor(props: OrderBarProps) {
    super(props);
    
    this.state = {
      showDrawer: false,
      selectedOrderMenuItem: {},
      isMenuItemModalOpen: false,
      isTop: true,
      isBottom: false
    };
  }
  
  animateDrawerIn = (gridContainer: any) => {
    return anime
      .timeline()
      .add({
        targets: gridContainer,
        translateX: 230,
        easing: easing,
        duration: 300
      }, 0).add({
        targets: [`.${styles.itemCountContainer}`, `.${styles.costContainer}`],
        opacity: createOpacityAnimationConfig(true)
      }, 50).add({
        targets: `.${styles.drawerContainer}`,
        opacity: createOpacityAnimationConfig(false)
      }, 50);
    
  };
  
  animateDrawerOut = (gridContainer: any) => {
    return anime
      .timeline()
      .add(
        {
          targets: gridContainer,
          translateX: 0,
          easing: easing,
          duration: 300
      }, 0).add({
        targets: [`.${styles.itemCountContainer}`, `.${styles.costContainer}`],
        opacity: createOpacityAnimationConfig(false)
      }, 50).add({
        targets: `.${styles.drawerContainer}`,
        opacity: createOpacityAnimationConfig(true)
      }, 50);
  };
  
  
  animateMobileDrawerIn = (gridContainer: any) => {
    //console.log('mobile drawer in');
    return anime
      .timeline()
      .add({
        targets: gridContainer,
        translateX: '101%',
        easing: easing,
        duration: 300
      }, 0).add({
        targets: [`.${styles.itemCountContainer}`, `.${styles.costContainer}`],
        opacity: createOpacityAnimationConfig(true)
      }, 50).add({
        targets: `.${styles.drawerContainer}`,
        opacity: createOpacityAnimationConfig(false)
      }, 50);
    
  };
  
  animateMobileDrawerOut = (gridContainer: any) => {
    //console.log('mobile drawer out');
    return anime
      .timeline()
      .add(
        {
          targets: gridContainer,
          translateX: '0%',
          easing: easing,
          duration: 300
        }, 50).add({
        targets: [`.${styles.itemCountContainer}`, `.${styles.costContainer}`],
        opacity: createOpacityAnimationConfig(false)
      }, 50).add({
        targets: `.${styles.drawerContainer}`,
        opacity: createOpacityAnimationConfig(true)
      }, 50);
  };
  
  /**
   *
   * @param {OrderMenuItemType} orderMenuItem
   * @private
   */
  _onEditMenuItem = (orderMenuItem: OrderMenuItemType): void => {
    
    this.setState({
      selectedOrderMenuItem: orderMenuItem,
      isMenuItemModalOpen: true
    });
  };
  
  /**
   *
   * @param {Object} params
   * @param {OrderMenuItemType} params.orderMenuItem
   * @returns {Promise<void>}
   * @private
   */
  _onOrderMenuItemUpdate = async (params: {orderMenuItem: OrderMenuItemType}) => {
    try {
      Logger.info(`_onOrderMenuItemUpdate: ${JSON.stringify(params)}`);
      const {orderMenuItem} = params;
      const modifiedTemporaryOrder = {...this.props.currentTemporaryOrder};
      
      if(isEmpty(modifiedTemporaryOrder.menuItems)) {
        modifiedTemporaryOrder.menuItems = [];
      }
      
      const orderMenuItemIndex = findIndex(modifiedTemporaryOrder.menuItems, (menuItem) => menuItem.id === orderMenuItem.id);
      
      if (orderMenuItemIndex < 0) {
        //add menuItem to modifiedTemporaryOrder
        modifiedTemporaryOrder.menuItems.push(orderMenuItem);
      } else {
        modifiedTemporaryOrder.menuItems[orderMenuItemIndex] = orderMenuItem;
      }
      
      return await this.props.updateTemporaryOrder({order: modifiedTemporaryOrder});
    } catch(error) {
      Logger.error(`Unexpected error in _onOrderMenuItemUpdate.`, error);
    }
  };
  
  /**
   *
   * @param {string} orderMenuItemId
   * @returns {Promise<void>}
   * @private
   */
  _onOrderMenuItemDelete = async (orderMenuItemId: string): Promise<void> => {
    try {
      const modifiedTemporaryOrder = {...this.props.currentTemporaryOrder};
      
      const orderMenuItemIndex = findIndex(modifiedTemporaryOrder.menuItems, (menuItem) => menuItem.id === orderMenuItemId);
      
      if(orderMenuItemIndex >= 0) {
        modifiedTemporaryOrder.menuItems.splice(orderMenuItemIndex, 1);
      }
      
      await this.props.updateTemporaryOrder({order: modifiedTemporaryOrder});
    } catch(error) {
      Logger.error(`Unexpected error in _onOrderMenuItemUpdate. [Error: ${error.message}]`);
    }
  };
  
  toggleMenuItemModal = () => {
    this.setState((prevState: OrderBarState) => {
      
      this.setState({isMenuItemModalOpen: !prevState.isMenuItemModalOpen});
    });
  };
  
  toggleDrawer = () => {
  
    this.setState((state) => {
      return {showDrawer: !state.showDrawer};
    });
  };
  
  /**
   *
   */
  handleCheckoutPress = () => {
    
    this.props.history.push(`/checkout?torderId=${this.props.currentTemporaryOrder._id}`);
  };
  
  onAddMoreClick = () => {
    const { currentTemporaryOrder } = this.props;
    
    
    const isCurrentPageRestaurant = (this.props.location.pathname.startsWith('/restaurant'));
    const currentOrderRestaurantId = currentTemporaryOrder.restaurantId;
    
    if(!isCurrentPageRestaurant) {
      this.props.history.push(`/restaurant/${currentOrderRestaurantId}`);
    } else {
      const currentPageRestaurantId = this.props.location.pathname.replace('/restaurant/', '');
      debugger;
      if(currentPageRestaurantId === currentOrderRestaurantId) {
        //minimize order bar
        this.setState({
          showDrawer: false
        })
      } else {
        //navigate to restaurant page
        this.props.history.push(`/restaurant/${currentOrderRestaurantId}`);
      }
    }
  };
  
  /**
   * @param {OrderMenuItemType[]} orderMenuItems
   * @return {*}
   */
  _renderMenuItemContainer = (orderMenuItems: OrderMenuItemType[]) => {
    
    let addMoreSection = null;
    if(orderMenuItems.length > 0) {
      addMoreSection = (
        <div className={styles.addMoreButton} onClick={this.onAddMoreClick}>
          <QbButton outline={true} onClick={this.onAddMoreClick}>
            Add More Items
          </QbButton>
        </div>
      );
    }
    
    return (
      <Fragment>
        <div className={styles.menuItemContainer}>
          {
            orderMenuItems.map((orderMenuItem: OrderMenuItemType, index: number) => {
              return (
                <Fragment key={orderMenuItem.id}>
                  <CheckoutMenuItem orderMenuItem={orderMenuItem}
                                    onEditMenuItem={this._onEditMenuItem}
                                    onOrderMenuItemDelete={this._onOrderMenuItemDelete} />
                  {(index !== orderMenuItems.length - 1) && <hr className={styles.breaker}/>}
                </Fragment>
              );
            })
          }
          { addMoreSection }
        </div>
      </Fragment>
    );
  };
  
  render() {
    const { currentTemporaryOrder } = this.props;
    
    const itemCount = (currentTemporaryOrder.menuItems || []).length;
    let orderCost: OrderCostType = { grandTotal: 0 };
    if(itemCount > 0) {
      orderCost = CostUtils.calculateCostForOrderManualV2(currentTemporaryOrder, {}, 0, false, {isDelivery: false});
    }
  
    const formattedOrderCost = NumberUtils.convertCentsToUSD(orderCost.grandTotal);
  
    const orderMenuItems: OrderMenuItemType[] = Object.values(currentTemporaryOrder.menuItems || {});
    
    return (
      <Fragment>
        <div className={cx('d-block d-sm-none', styles.mobileContainer)}> {/* xs */}
          <div className={styles.mobileMainRow}>
            <div className={styles.mobileItem}>Items: <Badge className={styles.badge} pill={true}>{itemCount}</Badge></div>
            <div className={styles.mobileItem}>Cost: <Badge className={styles.badge} pill={true}>{formattedOrderCost}</Badge></div>
            <div>
              <div className={styles.shoppingCartIcon} onClick={this.toggleDrawer}>
                <FontAwesomeIcon icon={['fal','shopping-cart']} fixedWidth={true} size='2x' className={styles.icon} />
              </div>
            </div>
          </div>
          <Transition in={this.state.showDrawer}
                      onEnter={this.animateMobileDrawerOut}
                      onExit={this.animateMobileDrawerIn}
                      unmountOnExit={false}
                      mountOnEnter={false}
                      timeout={300}>
            <div className={cx(styles.drawerMobileContainer)}>
              <QbButton onClick={this.handleCheckoutPress} disabled={orderMenuItems.length <= 0}>
                <div className={styles.buttonTextContainer}>
                  <div className={styles.buttonTextElement}>Checkout</div>
                  <div className={cx(styles.buttonTextElement, styles.lastButtonTextElement)}>{formattedOrderCost}</div>
                </div>
              </QbButton>
              { this._renderMenuItemContainer(orderMenuItems) }
            </div>
          </Transition>
        </div>
        <div className={'d-none d-sm-block'}>
          <Transition in={this.state.showDrawer}
                      onEnter={this.animateDrawerOut}
                      onExit={this.animateDrawerIn}
                      unmountOnExit={false}
                      mountOnEnter={false}
                      timeout={300}>
            <Fragment>
              {/* md+ */}
              <div className={cx(styles.container)}>
                <div className={styles.mainRow}>
                  <div className={styles.shoppingCartIcon} onClick={this.toggleDrawer}>
                    <FontAwesomeIcon icon={['fal','shopping-cart']} fixedWidth={true} size='2x' className={styles.icon} />
                  </div>
                </div>
                <div className={cx({'d-none': this.state.showDrawer}, styles.itemCountContainer)}>
                  <div className={styles.itemText}>Items</div>
                  <div className={styles.itemText}>
                    <Badge className={styles.badge} pill={true}>{itemCount}</Badge>
                  </div>
                </div>
                <div className={cx({'d-none': this.state.showDrawer}, styles.costContainer)}>
                  <div className={styles.itemText}>Total</div>
                  <div className={styles.itemText}>
                    <Badge className={styles.badge} pill={true}>{formattedOrderCost}</Badge>
                  </div>
                </div>
                <div className={cx({'d-none': !this.state.showDrawer}, styles.drawerContainer)}>
                  <QbButton className={styles.checkoutButton} onClick={this.handleCheckoutPress} disabled={orderMenuItems.length <= 0}>
                    <div className={styles.buttonTextContainer}>
                      <div className={styles.buttonTextElement}>Checkout</div>
                      <div className={cx(styles.buttonTextElement, styles.lastButtonTextElement)}>{formattedOrderCost}</div>
                    </div>
                  </QbButton>
                  
                  { this._renderMenuItemContainer(orderMenuItems) }
                </div>
              </div>
            </Fragment>
          </Transition>
        </div>
        <AddMenuItemModal isOpen={this.state.isMenuItemModalOpen}
                          toggle={this.toggleMenuItemModal}
                          isNew={false}
                          orderMenuItem={this.state.selectedOrderMenuItem}
                          onAddOrUpdateToOrder={this._onOrderMenuItemUpdate}/>
      </Fragment>
    );
  }
}

const OrderBarComponent = OrderBar;
const ConnectedOrderBar = withRouter(connect(OrderBar.mapStateToProps, OrderBar.mapDispatchToProps)(OrderBar));

export {
  OrderBarComponent,
  ConnectedOrderBar as OrderBar
};
