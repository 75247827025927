//@flow
import queryString from 'query-string';

import { ACTIONS } from '../ActionTypes';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';
import { getServerUrl } from '../utils/ConfigUtils';

import type { RestaurantType } from '../types';
import { Logger } from '../utils/LoggerService';



/**
 *
 * @param {Object} query
 * @return {function(*)}
 */
export const getFilteredRestaurants = (query: Object) => {
  return _getManyRestaurants(query, ACTIONS.GET_FILTERED_RESTAURANTS);
};

/**
 *
 * @param {Object} params
 * @return {function(*)}
 */
export const getManyRestaurants = (params: Object) => {
  return _getManyRestaurants(params, ACTIONS.GET_MANY_RESTAURANTS);
};

/**
 *
 * @param {Object} params
 * @param {String} actionType
 * @return {function(*)}
 * @private
 */
let _getManyRestaurants = (params, actionType) => {
  let newQuery;
  const baseUrl = `${getServerUrl()}/restaurants`;
  let url = baseUrl;
  
  if(params) {
    let localQueryObject = {};
    Object.keys(params).forEach(item => {
      let itemValue = params[item];
      if(Array.isArray(itemValue)) {
        localQueryObject[item] = itemValue.join(',');
      } else {
        localQueryObject[item] = itemValue;
      }
    });
    
    newQuery = queryString.stringify(localQueryObject);
    
    if(newQuery.length > 0) {
      url = `${baseUrl}?${newQuery}`;
    }
  }
  
  return async (dispatch: any) => {
    
    const retrievedRestaurants = await ApiFetchUtils.ApiFetch(url);
    
    dispatch({
      type: actionType,
      payload: {
        restaurants: retrievedRestaurants
      }
    });
    
    return retrievedRestaurants;
  };
};

/**
 *
 * @param {{subdomain: string}} params
 * @returns {function(*): RestaurantType}
 */
export const getRestaurantBySubdomain = (params: {subdomain: string}) => {
  const { subdomain } = params;

  return async (dispatch: any) => {
  
    const url: string = `${getServerUrl()}/restaurants?subdomain=${subdomain}`;
  
    const retrievedRestaurants: RestaurantType[] = await ApiFetchUtils.ApiFetch(url);
  
    const singleRestaurant = retrievedRestaurants[0];
    
    dispatch({
      type: ACTIONS.GET_RESTAURANT,
      payload: {
        restaurant: singleRestaurant
      }
    });
    
    return singleRestaurant;
  };
};

export const getRestaurantBySlug = (params: {slug: string}) => {
  const { slug } = params;
  
  return async (dispatch: any) => {
  
    const url: string = `${getServerUrl()}/restaurants?slug=${slug}`;
  
    const retrievedRestaurants: RestaurantType[] = await ApiFetchUtils.ApiFetch(url);
  
    const singleRestaurant = retrievedRestaurants[0];
  
    dispatch({
      type: ACTIONS.GET_RESTAURANT,
      payload: {
        restaurant: singleRestaurant
      }
    });
  
    return singleRestaurant;
  };
};

/**
 *
 * @param {{restaurantId: string}} params
 * @returns {function(*)}
 */
export const getRestaurant = (params: {restaurantId: string}) => {
  const {restaurantId} = params;
  
  const url = `${getServerUrl()}/restaurants/${restaurantId}`;
  
  return async (dispatch: any) => {
    const retrievedRestaurant: RestaurantType = await ApiFetchUtils.ApiFetch(url);
    
    dispatch({
      type: ACTIONS.GET_RESTAURANT,
      payload: {
        restaurant: retrievedRestaurant
      }
    });
    
    return retrievedRestaurant;
  };
};

/**
 *
 * @param {{restaurantId: string, body: RestaurantType}} params
 * @returns {function(*)}
 */
export const putRestaurant = (params: {restaurantId: string, body: RestaurantType}) => {
  const { restaurantId, body } = params;
  
  return async (dispatch: any) => {
    Logger.info('Calling putRestaurant');
    dispatch({
      type: ACTIONS.PUT_RESTAURANT_START,
      payload: {
        restaurant: body,
      },
    });
    
    const updatedRestaurant = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/restaurants/${restaurantId}`, {
      method: 'PUT',
      body: body,
    });
    
    dispatch({
      type: ACTIONS.PUT_RESTAURANT_SUCCESS,
      payload: {
        restaurant: updatedRestaurant,
      },
    });
    
    return updatedRestaurant;
  };
};

/**
 *
 * @returns {function(*): *}
 */
export const clearSearchedRestaurants = () => {
  return (dispatch: any) => {
    return dispatch({
      type: ACTIONS.CLEAR_SEARCHED_RESTAURANTS
    });
  };
};
