import React, { PureComponent } from 'react';
import {geolocated, GeolocatedProps} from 'react-geolocated';
import { isEqual } from 'lodash';

type GeolocationProps = {
  ...GeolocatedProps;
}

class Geolocation extends PureComponent<GeolocationProps> {
  
  componentDidMount() {
    console.log(`${JSON.stringify(this.props)}`);
    if(this.props.isGeolocationAvailable && this.props.isGeolocationEnabled) {
      if(this.props.coords) {
        console.log(`Geolocation: ${JSON.stringify(this.props.coords)}`);
      }
    }
  }
  
  componentDidUpdate(prevProps) {
    console.log(`${JSON.stringify(this.props)}`);
    if(this.props.isGeolocationAvailable && this.props.isGeolocationEnabled) {
      if(!isEqual(this.props.coords, prevProps.coords)) {
        console.log(`Geolocation: ${JSON.stringify(this.props.coords)}`);
      }
    }
  }
  
  render() {
    return (<div className={'geolocation'} />);
  }
}

const Wrapped = geolocated({
  positionOptions: {
    enableHighAccuracy: true
  },
  userDecisionTimeout: 10000,
})(Geolocation);

export {
  Wrapped as Geolocation
};
