import React, { lazy, Suspense } from 'react';

const AdminPortal = lazy(() => import('./AdminPortal').then(module => ({ default: module.AdminPortal })));

const LoadingAdminPortal = () => {
  
  return <div>Loading...</div>
};

const SuspensedAdminPortal = (props) => {
  
  return (
    <Suspense fallback={<LoadingAdminPortal />}>
      <AdminPortal {...props} />
    </Suspense>
  );
};

export { SuspensedAdminPortal as AdminPortal };
