import { isEmpty } from 'lodash';
import {PROMPT_TYPE} from '@foodbuzzer/foodbuzzer-shared/constants';

import type { MenuItemChoiceType } from '../types';

/**
 *
 * @param {MenuItemChoiceType} menuItemChoice
 * @param {*} selectedOption
 * @returns {Array}
 */
export const getSelectedChoiceData = (menuItemChoice: MenuItemChoiceType, selectedOption: any): {optionId: string, optionText: string, optionPrice: number}[] => {
  const results = [];
  
  if(isEmpty(menuItemChoice) || isEmpty(selectedOption)) {
    return results;
  }
  
  if(menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_ONE ||
    menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_MANY ||
    menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_N_ITEMS) {
    const choiceOptionLength = (menuItemChoice.options || []).length;
    for (let index = 0; index < choiceOptionLength; index++) {
      const option = menuItemChoice.options[index];
      if (option.id in selectedOption) {
        results.push({
          optionId: option.id,
          optionText: option.name,
          optionPrice: option.price
        });
      }
    }
  }
  
  return results;
};
