//@flow


/**
 *
 * @param {string} key
 * @param {*} value
 */
export const setItem = (key: string, value: any) => {
  
  localStorage.setItem(key, value);
};

/**
 *
 * @param {string} key
 * @returns {*}
 */
export const getItem = (key: string): ?string => {
  
  if (localStorage.hasOwnProperty(key)) {
    return localStorage.getItem(key);
  } else {
    return null;
  }
};

/**
 *
 * @param {string} key
 */
export const removeItem = (key: string) => {
  if(localStorage.hasOwnProperty(key)) {
    localStorage.removeItem(key);
  }
};
