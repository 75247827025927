//@flow
import { ACTIONS } from '../ActionTypes';
import type { ActionType, RestaurantsStateType } from '../types';

export const getInitialState = (): RestaurantsStateType => {
  return {
    byId: {},
    bySubdomain: {},
    bySlug: {},
    filteredById: {},
    viewMenuById: {}
  };
};


export default (state: RestaurantsStateType = getInitialState(), action: ActionType) => {
  let newById;
  let newBySubdomain;
  let newBySlug;
  switch (action.type) {
    case ACTIONS.GET_MANY_RESTAURANTS: {
      newById = {...state.byId};
      newBySubdomain = { ...state.bySubdomain };
      newBySlug = { ...state.bySlug };
      action.payload.restaurants.forEach(restaurant => {
        newById[restaurant._id] = restaurant;
        if(restaurant.subdomain) {
          newBySubdomain[restaurant.subdomain] = restaurant;
        }
        if(restaurant.slug) {
          newBySlug[restaurant.slug] = restaurant;
        }
      });
      
      return {
        ...state,
        byId: newById,
        bySubdomain: newBySubdomain,
        bySlug: newBySlug
      };
    }
    
    case ACTIONS.GET_FILTERED_RESTAURANTS: {
      newById = {};
      action.payload.restaurants.forEach(restaurant => {
        newById[restaurant._id] = restaurant;
      });
      
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById,
        },
        filteredById: newById
      };
    }
    case ACTIONS.GET_RESTAURANT:
    case ACTIONS.PUT_RESTAURANT_SUCCESS:
    case ACTIONS.POST_RESTAURANT_SUCCESS: {
      const { restaurant } = action.payload;
      newById = {
        ...state.byId,
        [restaurant._id]: restaurant
      };
  
      newBySubdomain = {
        ...state.bySubdomain,
        [restaurant.subdomain]: restaurant
      };
  
      newBySlug = {
        ...state.bySlug,
        [restaurant.slug]: restaurant
      };
      
      return {
        ...state,
        byId: newById,
        bySubdomain: newBySubdomain,
        bySlug: newBySlug
      };
    }
    
    case ACTIONS.CLEAR_SEARCHED_RESTAURANTS: {
      return {
        ...state,
        filteredById: {}
      };
    }
    
    case ACTIONS.VIEW_MENU_FOR_RESTAURANT: {
      const { restaurantId } = action.payload;
      
      return {
        ...state,
        viewMenuById: {
          ...state.viewMenuById,
          [restaurantId]: true
        }
      };
    }
    
    default:
      return state;
  }
};
