import { ACTIONS } from '../ActionTypes';

import type { RestaurantLocationsStateType, ActionType } from '../types';

const getInitialState = (): RestaurantLocationsStateType => {
  return {
    byId: {},
    byRestaurantId: {},
    filteredById: {}
  };
};


export default (state: RestaurantLocationsStateType = getInitialState(), action: ActionType) => {
  let newById;
  let newByRestaurantId;
  switch (action.type) {
    case ACTIONS.GET_MANY_RESTAURANT_LOCATIONS: {
      newById = { ...state.byId };
      newByRestaurantId = { ...state.byRestaurantId };
      action.payload.restaurantLocations.forEach(restaurantLocation => {
        newById[restaurantLocation._id] = restaurantLocation;
        
        if(!(restaurantLocation.restaurantId in newByRestaurantId)) {
          newByRestaurantId[restaurantLocation.restaurantId] = {};
        }
        
        newByRestaurantId[restaurantLocation.restaurantId][restaurantLocation._id] = restaurantLocation;
      });
      
      return {
        ...state,
        byId: newById,
        byRestaurantId: newByRestaurantId,
        filteredById: newById
      };
    }
    
    case ACTIONS.GET_FILTERED_RESTAURANT_LOCATIONS: {
      newById = {};
      newByRestaurantId = { ...state.byRestaurantId };
      action.payload.restaurantLocations.forEach(restaurantLocation => {
        newById[restaurantLocation._id] = restaurantLocation;
        
        if(!(restaurantLocation.restaurantId in newByRestaurantId)) {
          newByRestaurantId[restaurantLocation.restaurantId] = {};
        }
        
        newByRestaurantId[restaurantLocation.restaurantId][restaurantLocation._id] = restaurantLocation;
      });
      
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById
        },
        byRestaurantId: newByRestaurantId,
        filteredById: newById
      };
    }
    
    case ACTIONS.PUT_RESTAURANT_LOCATION_START:
    case ACTIONS.GET_RESTAURANT_LOCATION:
    case ACTIONS.PUT_RESTAURANT_LOCATION_SUCCESS:
    case ACTIONS.POST_RESTAURANT_LOCATION_SUCCESS: {
      const { restaurantLocation } = action.payload;
      newById = {
        ...state.byId,
        [restaurantLocation._id]: restaurantLocation
      };
      
      newByRestaurantId = { ...state.byRestaurantId };
      
      if(!(restaurantLocation.restaurantId in newByRestaurantId)) {
        newByRestaurantId[restaurantLocation.restaurantId] = {};
      }
      newByRestaurantId[restaurantLocation.restaurantId][restaurantLocation._id] = restaurantLocation;
      
      return {
        ...state,
        byId: newById,
        byRestaurantId: newByRestaurantId
      };
    }
    
    default:
      return state;
  }
};
