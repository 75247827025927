//@flow
import React, { PureComponent } from 'react';
import { App } from '../App';
import { Provider } from 'react-redux'
import {StoreContext} from 'redux-react-hook';
import { createStore, applyMiddleware, compose } from 'redux';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';
import { setItem } from '../../services/LocalStorageService';

//middleware
import thunk from 'redux-thunk';
//import { refreshAuthMiddleware } from '../middleware/refreshAuth';

import reducers from '../../reducers'
import { REDUX_STATE_KEY, loadStateFromLocalStorage } from '../../utils/ReducerUtils';
import type { StateType } from '../../types';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];


const persistedState: StateType = loadStateFromLocalStorage();
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(...middleware)));

store.subscribe(() => {
  setItem(REDUX_STATE_KEY, JSON.stringify(store.getState()))
});

ApiFetchUtils.setReduxStore(store);

type RootProps = {};

class Root extends PureComponent<RootProps> {

  render() {
    return (
      <Provider store={store}>
        <StoreContext.Provider value={store}>
          <App />
        </StoreContext.Provider>
      </Provider>
    );
  }
}

export { Root };
