//@flow

import React, { lazy, Suspense } from 'react';

const EditRestaurant = lazy(() => import('./EditRestaurant').then(module => ({ default: module.EditRestaurant })));

const LoadingEditRestaurant = () => {
  
  return <div>Loading...</div>
};

const SuspensedEditRestaurant = (props: any) => {
  
  return (
    <Suspense fallback={<LoadingEditRestaurant />}>
      <EditRestaurant {...props} />
    </Suspense>
  );
};

export { SuspensedEditRestaurant as EditRestaurant };
