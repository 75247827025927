//@flow
import React from 'react';
import { Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cns from 'classnames/bind';

import styles from './QuantityInput.module.scss';

const cx = cns.bind(styles);

type QuantityInputProps = {
  label: string;
  value: number;
  onChange: (newValue: number) => void;
  containerClassName: any;
}

const QuantityInput = (props: QuantityInputProps) => {
  const { label = '', value = 0, onChange, containerClassName } = props;
  
  return (
    <div className={cx(styles.container, containerClassName)}>
      { !isEmpty(label) && <Label>{label}</Label>}
      <div id={"decrement"} className={cx(styles.clickable)} onClick={() => onChange(value - 1)}>
        <FontAwesomeIcon icon={['fal', 'minus-circle']} size={'lg'} />
      </div>
      <div className={styles.displayValue}>
        {value}
      </div>
      <div id={"increment"} className={cx(styles.clickable)} onClick={() => onChange(value + 1)} >
        <FontAwesomeIcon icon={['fal', 'plus-circle']} size={'lg'} />
      </div>
    </div>
  );
};

export { QuantityInput };
