import React, { lazy, Suspense } from 'react';

const OrderDetails = lazy(() => import('./OrderDetails').then(module => ({ default: module.OrderDetails })));

const LoadingOrderDetails = () => {
  
  return <div>Loading...</div>
};

const SuspensedOrderDetails = (props) => {
  
  return (
    <Suspense fallback={<LoadingOrderDetails />}>
      <OrderDetails {...props} />
    </Suspense>
  );
};

export { SuspensedOrderDetails as OrderDetails };
