import React, { Suspense, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { AdminRoute } from '../../components/AdminRoute';
import cns from 'classnames/bind';


import styles from './AppRouter.module.scss';


//Routes
import { Header } from '../../components/Header';
//import { Home } from '../Home';
import { Search } from '../Search';
import { Auth, AuthCallback } from '../Auth';
import { Footer } from '../../components/Footer';
import { RestaurantMenu } from '../RestaurantMenu';
import { OrderBar } from '../OrderBar';
import { Checkout } from '../Checkout';
import { Logger } from '../../utils/LoggerService';
import { renewAuth, initializeAuth } from '../../actions';


import { getSubdomainFromHostName } from '../../utils/UrlUtils';
import { Profile } from '../Profile';
import { OrderDetails } from '../OrderDetails';

//Admin Routes
import { AdminPortal } from '../AdminPortal';
import { EditRestaurant as AdminEditRestaurant } from '../Admin/EditRestaurant';
import { EditRestaurantDetails as AdminEditRestaurantDetails } from '../Admin/EditRestaurantDetails';
import { EditRestaurantSchedule as AdminEditRestaurantSchedule } from '../Admin/EditRestaurantSchedule';
import { EditMenuItem as AdminEditMenuItem } from '../Admin/EditMenuItem';



import type { StateType } from '../../types';

const cx = cns.bind(styles);

const FIVE_MINUTES: number = 60 * 5;


const LoadingMessage = () => "Loading...";


type AppRouterProps = {
  isAuthenticated: boolean;
  expirationTime: number;
  renewAuth: () => void;
  initializeAuth: () => void;
}

class AppRouter extends PureComponent<AppRouterProps> {
  
  authRenewalTimeout: any = null;
  
  static mapStateToProps = (state: StateType) => {
    const { auth } = state;
    
    return {
      isAuthenticated: auth.isAuthenticated,
      idToken: auth.idToken,
      expirationTime: auth.expirationTime
    };
  };
  
  static mapDispatchToProps = (dispatch: any) => {
  
    return {
      renewAuth: () => dispatch(renewAuth()),
      initializeAuth: () => dispatch(initializeAuth())
    };
  };
  
  async componentDidMount() {
    try {
      Logger.info('AppRouter.componentDidMount');
      
      await this.props.initializeAuth();
  
      //if user is logged in && timeout is not set
      // set renewalTimeout
      if (this.props.isAuthenticated && this.authRenewalTimeout === null) {
        this._startAuthRenewalTimeout();
      }
  
      if (!this.props.isAuthenticated && !this.props.idToken) {
        //we can attempt token renewal
        await this.props.renewAuth();
      }
    } catch(err) {
      Logger.error('Unexpected error in componentDidMount', err);
    }
  }
  
  componentDidUpdate(prevProps: AppRouterProps): void {
    Logger.info('AppRouter.componentDidUpdate');
  
    //transition from NOT LOGGED IN => LOGGED IN
    if(this.props.isAuthenticated === true && prevProps.isAuthenticated === false) {
      this._startAuthRenewalTimeout();
    }
  
    //transition from LOGGED IN => NOT LOGGED IN
    if(this.props.isAuthenticated === false && prevProps.isAuthenticated === true) {
      this._stopRenewalTimeout();
    }
  }
  
  componentWillUnmount(): void {

    this._stopRenewalTimeout();
  }
  
  /**
   *
   * @private
   */
  _startAuthRenewalTimeout = () => {
    
    if(this.authRenewalTimeout === null) {
      
      //add 5 minute buffer to refresh sooner than expiration
      const delayInSeconds = this.props.expirationTime - ((Date.now()/1000) + FIVE_MINUTES);
      if (delayInSeconds > 0) {
        Logger.info('Start Renewal Timeout');
        this.authRenewalTimeout = setTimeout(async () => {
        
          //refresh token
          await this.props.renewAuth();
          
          this._stopRenewalTimeout();
          this._startAuthRenewalTimeout();
        }, delayInSeconds * 1000);
      }
    }
  };
  
  
  _stopRenewalTimeout = () => {
    if(this.authRenewalTimeout !== null) {
      //clearTimeout
      Logger.info('Stop Renewal Timeout');
      clearTimeout(this.authRenewalTimeout);
      this.authRenewalTimeout = null;
    }
  };
  
  _shouldShowOrderBar = (): boolean => {
    
    const currentPath = this.props.location.pathname;
    console.log(currentPath);
    if(currentPath === '/login' ||
       currentPath === '/checkout' ||
       currentPath.startsWith('/admin'))  {
      return false;
    }
    
    return true;
  };
  
  render() {
  
    return (
      <Fragment>
        <Header/>
        <div className={cx('container-fluid', styles.mainContainer)}>
          {this._shouldShowOrderBar() && <OrderBar/> }
          <div className={cx('container-fluid', 'col-12', 'col-md-10', 'offset-md-1', styles.contentContainer)}>
            <Suspense fallback={<LoadingMessage />}>
              <Switch>
                {/*<Route path='/search' exact component={Search} />*/}
                
                <Route path='/signup' exact component={Auth} />
                <Route path='/login' exact component={Auth} />
                <Route path={'/auth/callback'} exact component={AuthCallback} />
                <Route path={'/checkout'} exact component={Checkout} />
                <Route path={'/profile'} exact component={Profile} />
                <Route path={'/orders/:orderId'} exact component={OrderDetails} />
                <Route path='/restaurant/s/:slugname' exact component={RestaurantMenu} />
                <Route path='/restaurant/:restaurantId' exact component={RestaurantMenu} />
  
                {/* Admin Portal */}
                <AdminRoute path={'/admin/restaurant/details/:restaurantId'} exact component={AdminEditRestaurantDetails} />
                <AdminRoute path={'/admin/restaurant/schedule/:restaurantId'} exact component={AdminEditRestaurantSchedule} />
                <AdminRoute path={'/admin/restaurant/addmenuitem'} exact component={AdminEditMenuItem} />
                <AdminRoute path={'/admin/restaurant/menuitem/:menuItemId'} exact component={AdminEditMenuItem} />
                <AdminRoute path={'/admin/restaurant/:restaurantId'} exact component={AdminEditRestaurant} />
                
                <AdminRoute path={'/admin'} exact component={AdminPortal} />
                
                {/* There are two different types of home pages...main home page and per restaurant order page */}
                
                <Route path='/' exact render={(props) => {
                  const subdomain = getSubdomainFromHostName(window.location.hostname);
                  if(subdomain !== '') {
                    return (<RestaurantMenu subdomain={subdomain} {...props} />);
                  } else {
                    return (<Search {...props} />);
                  }
                }} />
              </Switch>
            </Suspense>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }
}

const withRouterAppRouter = withRouter(connect(AppRouter.mapStateToProps, AppRouter.mapDispatchToProps)(AppRouter));

export {
  withRouterAppRouter as AppRouter
};
