//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cns from 'classnames/bind';

import styles from './Footer.module.scss';

const cx = cns.bind(styles);


const PRIVACY_POLICY_URL: string = 'https://s3.amazonaws.com/quickbite-static-resources/policies/privacypolicy.html';
const TERMS_OF_SERVICE_URL: string = 'https://app.termly.io/document/terms-of-use-for-online-marketplace/b41e695d-6636-4b75-a950-41d84ba66ee6';

type FooterProps = {
  history: any;
}

const Footer = (props: FooterProps) => {
  
  return (
    <footer className={styles.footer}>
      <div className={cx('container col-12 col-md-10 offset-md-1' )}>
        <div className={cx(styles.innerContainer, 'row')}>
          <div className={styles.navLinks}>
            <div>Home</div>
            <ul className={'fa-ul'}>
              <li className={styles.footerLink} onClick={() => props.history.push('/')}>
                <span className='fa-li'>
                  <FontAwesomeIcon icon={['fal','home']} fixedWidth={true}  />
                </span>
                Home
              </li>
              <li className={styles.footerLink}>
                <span className='fa-li'>
                  <FontAwesomeIcon icon={['fal','file']} fixedWidth={true}  />
                </span>
                <a href={PRIVACY_POLICY_URL} target={'_blank'}>Privacy Policy</a>
              </li>
              <li className={styles.footerLink}>
                <span className='fa-li'>
                  <FontAwesomeIcon icon={['fal','file-alt']} fixedWidth={true}  />
                </span>
                <a href={TERMS_OF_SERVICE_URL} target={'_blank'}>Terms of Service</a>
              </li>
            </ul>
          </div>
          <div>
            <div>Order with QuickBite! from your phone or tablet</div>
            <div>
              <a href="https://itunes.apple.com/us/app/quickbite/id1414959028?pt=117289805&ct=website&mt=8"
                 style={{
                   display: "inline-block",
                   color: 'transparent',
                   overflow: "hidden",
                   background:'url("https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-09-24&kind=iossoftware&bubble=ios_apps") no-repeat',
                   width: '135px',
                   height: '40px',
                   margin: '5px 11px'}} >Apple App Store</a>
            </div>
            <div>
              <a href='https://play.google.com/store/apps/details?id=com.quickbiteconsumerapp.prod&utm_campaign=website&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play'
                     style={{width: '160px'}}
                     src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const ConnectedFooter = withRouter(Footer);

export {
  ConnectedFooter as Footer
};
