//@flow
import { Promise as BluebirdPromise } from 'bluebird';
import moment from 'moment';
import { get, isEmpty } from 'lodash';

import { ACTIONS } from '../ActionTypes';
import { AuthService } from '../services/AuthService';
import { Logger } from '../utils/LoggerService';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';
import { getServerUrl } from '../utils/ConfigUtils';

import type { StateType, UserProfileType } from '../types';


let authService: AuthService = null;

/**
 *
 * @returns {AuthService}
 * @private
 */
export const _getAuthService = () => {
  if(authService === null) {
    authService = new AuthService();
  }
  
  return authService;
};

/**
 *
 * @returns {Function}
 */
export const logout = () => {
  Logger.info('Calling logout.');
  return async (dispatch: any) => {
    await _getAuthService().logout();
    
    //await MixpanelAnalytics.reset();
    
    dispatch({
      type: ACTIONS.LOGOUT_SUCCESS
    });
  };
};

/**
 *
 * @param {{email: string, password: string}} loginRequestParams
 * @returns {function(*): {successfulLogin: boolean, userProfile?: string, idToken?: string, error?: Error}}
 */
export const loginRequest = (loginRequestParams: {email: string, password: string}) => {
  Logger.info('Calling loginRequest.');
  const {email, password} = loginRequestParams;
  return async (dispatch: any) => {
    
    let successfulLogin = false;
    let loginRequestPayload = {};
    try {
      dispatch({
        type: ACTIONS.LOGIN_REQUEST
      });
      
      const loginParams = {
        email,
        password
      };
      
      await _getAuthService().login(loginParams);
      
      // const profile = AuthService.decodeToken(credentials.idToken);
      // const userId = get(profile, '["https://getquickbite.com/app_metadata"].userId', '');
      // const isAdmin = get(profile, '["https://getquickbite.com/app_metadata"].isAdmin', false);
      //
      // await BluebirdPromise.all([
      //   AuthService.setRefreshToken(credentials.refreshToken),
      //   AuthService.setToken(credentials.idToken),
      //   AuthService.setUserId(userId)
      // ]);
      //
      // const fetchOptions = {
      //   headers: {
      //     Authorization: `Bearer ${credentials.idToken}`
      //   }
      // };
      //
      // //Retrieve user profile and set data
      // const url = `${getServerUrl()}/userprofiles/${userId}`;
      //
      // const userProfile: UserProfileType = await ApiFetchUtils.ApiFetch(url, fetchOptions);
      //
      //
      // userProfile.lastSignInDateTime = moment().utc().format();
      //
      // loginRequestPayload = {
      //   userProfile: userProfile,
      //   idToken: credentials.idToken,
      //   isAdmin,
      //   expirationTime: AuthService.getTokenExpirationUtc(credentials.idToken)
      // };
      
      //set user context for sentry
      //Logger.info(`loginRequest.setUserContext. [UserProfile: ${JSON.stringify(userProfile)}]`);
      // Sentry.setUserContext({
      //   email: userProfile.email,
      //   userID: userProfile._id
      // });
      
      // dispatch({
      //   type: ACTIONS.LOGIN_SUCCESS,
      //   payload: loginRequestPayload
      // });
      
      // await MixpanelAnalytics.assignUserProfileId(userProfile._id);
      // await MixpanelAnalytics.setPeopleProperties({
      //   email: userProfile.email,
      //   firstName: userProfile.firstName,
      //   lastName: userProfile.lastName
      // });
      
      successfulLogin = true;
    } catch(error) {
      Logger.error(`Unexpected error requestLogin.`, error);
      //$FlowFixMe
      loginRequestPayload.error = error;
      dispatch({
        type: ACTIONS.LOGIN_ERROR,
        payload: {
          errorMessage: error.message
        }
      });
    }
    
    return {
      successfulLogin,
      ...loginRequestPayload
    };
  };
};

/**
 *
 * @param {{firstName: string, lastName: string, email: string, password: string}} params
 * @returns {Function}
 */
export const registerCustomer = (params: {firstName: string, lastName: string, email: string, password: string}) => {
  Logger.info('Calling registerCustomer.');
  const { firstName, lastName, email, password } = params;
  
  return async (dispatch: any) => {
    
    const payload = {
      firstName,
      lastName,
      email,
      password
    };
    
    const result = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/userprofiles`, {
      method: 'POST',
      body: payload
    });
    
    // await MixpanelAnalytics.assignAlias(result._id);
    // await MixpanelAnalytics.setPeopleProperties({
    //   email: result.email,
    //   firstName: result.firstName,
    //   lastName: result.lastName
    // });
    
    return result;
  };
};


/**
 *
 * @param {{targetPath?: string}} loginRequestParams
 * @returns {function(any): {successfulLogin: boolean}}
 */
export const googleLoginRequest = (loginRequestParams: {targetPath?: string} = {}) => {
  Logger.info('Google Login Attempt');
  return async (dispatch: any) => {
    let successfulLogin = false;
    try {
      dispatch({
        type: ACTIONS.LOGIN_REQUEST
      });
      
      await _getAuthService().googleLogin(loginRequestParams);
      
      successfulLogin = true;
    } catch(err) {
      if(err.error === 'a0.session.user_cancelled') {
        //ignore error since user just didnt want to sign in
      } else {
        Logger.error(`Unexpected googleLoginRequest error: [ErrorType: ${err.name}] [Message: ${err.message}] [Error: ${err.error}]`);
      }
    }
    
    return {
      successfulLogin
    };
  };
};

/**
 *
 * @param {{targetPath?: string}} loginRequestParams
 * @returns {function(any): {successfulLogin: boolean}}
 */
export const facebookLoginRequest = (loginRequestParams: {targetPath?: string} = {}) => {
  Logger.info('Facebook Login Attempt');
  return async (dispatch: any) => {
    let successfulLogin = false;
    try {
      dispatch({
        type: ACTIONS.LOGIN_REQUEST
      });
      
      await _getAuthService().facebookLogin(loginRequestParams);
      
      successfulLogin = true;
    } catch(error) {
      if(error.error === 'a0.session.user_cancelled') {
        //ignore error since user just didnt want to sign in
      } else {
        Logger.error(`Unexpected facebookLoginRequest error: [ErrorType: ${error.name}] [Message: ${error.message}] [Error: ${error.error}]`);
        dispatch({
          type: ACTIONS.LOGIN_ERROR,
          payload: {
            errorMessage: null
          }
        });
      }
    }
    
    return {
      successfulLogin
    };
  };
};




/**
 *
 * @param {function} dispatch
 * @param {Object} credentials
 * @returns {Promise<Object>}
 * @private
 */
const _processSocialLogin = async (dispatch: any, credentials: {idToken: string, refreshToken: string}): Promise<Object> => {
  Logger.info('Calling _processSocialLogin.');
  const profile = AuthService.decodeToken(credentials.idToken);
  
  const userId = get(profile, '["https://getquickbite.com/app_metadata"].userId', '');
  const isAdmin = get(profile, '["https://getquickbite.com/app_metadata"].isAdmin', false);
  if(!userId) {
    const customerLoginUrl = `${getServerUrl()}/customerlogin`;
    const customerLoginOptions = {
      method: 'POST',
      body: profile,
      headers: {
        Authorization: `Bearer ${credentials.idToken}`
      }
    };
    
    const userProfile = await ApiFetchUtils.ApiFetch(customerLoginUrl, customerLoginOptions);
    
    Logger.info(`userProfile: ${JSON.stringify(userProfile)}`);
    await BluebirdPromise.all([
      AuthService.setToken(credentials.idToken),
      AuthService.setUserId(userProfile._id)
    ]);
    
    //set user context for sentry
    Logger.info(`No existing user _processSocialLogin.setUserContext. [UserProfile: ${JSON.stringify(userProfile)}]`);
    // Sentry.setUserContext({
    //   email: userProfile.email,
    //   userID: userProfile._id
    // });
    
    
    dispatch({
      type: ACTIONS.LOGIN_SUCCESS,
      payload: {
        userProfile,
        loggedInUserId: userProfile._id,
        idToken: credentials.idToken,
        isAdmin,
        expirationTime: AuthService.getTokenExpirationUtc(credentials.idToken)
      }
    });
    
    // await MixpanelAnalytics.assignAlias(userProfile._id);
    // await MixpanelAnalytics.setPeopleProperties({
    //   email: userProfile.email,
    //   firstName: userProfile.firstName,
    //   lastName: userProfile.lastName
    // });
    
    return userProfile;
    
  } else {
    Logger.info(`Loading existing user. [UserId: ${userId}]`);
    await BluebirdPromise.all([
      AuthService.setToken(credentials.idToken),
      AuthService.setUserId(userId)
    ]);
    
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${credentials.idToken}`
      }
    };
    
    
    //Retrieve user profile and set data
    const url = `${getServerUrl()}/userprofiles/${userId}`;
    
    const userProfile = await ApiFetchUtils.ApiFetch(url, fetchOptions);
    userProfile.lastSignInDateTime = moment().utc().format();
  
    //set user context for sentry
    Logger.info(`Existing user _processSocialLogin.setUserContext. [UserProfile: ${JSON.stringify(userProfile)}]`);
    // Sentry.setUserContext({
    //   email: userProfile.email,
    //   userID: userProfile._id
    // });
    
    dispatch({
      type: ACTIONS.LOGIN_SUCCESS,
      payload: {
        userProfile: userProfile,
        loggedInUserId: userProfile._id,
        idToken: credentials.idToken,
        isAdmin,
        expirationTime: AuthService.getTokenExpirationUtc(credentials.idToken)
      }
    });
    
    // await MixpanelAnalytics.assignUserProfileId(userProfile._id);
    // await MixpanelAnalytics.setPeopleProperties({
    //   email: userProfile.email,
    //   firstName: userProfile.firstName,
    //   lastName: userProfile.lastName
    // });
    
    return userProfile;
  }
  
  
};


export const loginCallback = (params: Object) => {
  Logger.info('Calling loginCallback');
  
  // const { history, passThroughParams } = loginCallbackParams;
  const { hash } = window.location;
  
  return async (dispatch: any) => {
  
    let successfulLogin = false;
    let nextPath;
    let userProfile;
    try {
      const response = await _getAuthService().parseInfo(hash);
      
      const credentials = {
        idToken: response.idToken || response.id_token,
        refreshToken: ''
      };
  
      nextPath = get(response, 'state.targetPath', '');
  
      userProfile = await _processSocialLogin(dispatch, credentials);
  
      successfulLogin = true;
    } catch(err) {
      successfulLogin = false;
      Logger.error('Unexpected error in loginCallback.', err);
    }
  
    return {
      successfulLogin,
      nextPath,
      userProfile
    };
  };
};

/**
 *
 * @returns {Function}
 */
export const renewAuth = () => {

  return async (dispatch: any, getState: () => StateType) => {
    try {
      Logger.info('Calling renewAuth.');
      const {auth} = getState();
  
      const result = await _getAuthService().renewTokens();
  
      await AuthService.setToken(result.idToken);
  
      const loggedInUserId = AuthService.getUserIdFromToken(result.idToken);
  
      let loggedInUser = auth.loggedInUser;
      if (isEmpty(auth.loggedInUser)) {
        const fetchOptions = {
          headers: {
            Authorization: `Bearer ${result.idToken}`
          }
        };
    
        //Retrieve user profile and set data
        const url = `${getServerUrl()}/userprofiles/${loggedInUserId}`;
    
        loggedInUser = await ApiFetchUtils.ApiFetch(url, fetchOptions);
      }
  
      dispatch({
        type: ACTIONS.LOGIN_SUCCESS,
        payload: {
          userProfile: loggedInUser,
          loggedInUserId: loggedInUserId,
          idToken: result.idToken,
          isAdmin: auth.isAdmin,
          expirationTime: AuthService.getTokenExpirationUtc(result.idToken)
        }
      });
      
    } catch(err) {
      Logger.error('Unexpected error in renewAuth.', err);
    }
  };
};


/**
 *
 * @param {{userProfileId: string}} params
 * @returns {function(any)}
 */
export const getLoggedInUserProfile = (params: {userProfileId: string}) => {
  Logger.info('Calling getLoggedInUserProfile.');
  const { userProfileId } = params;
  
  return async (dispatch: any) => {
    const url = `${getServerUrl()}/userprofiles/${userProfileId}`;
    
    const retrievedUserProfile = await ApiFetchUtils.ApiFetch(url);
    
    dispatch({
      type: ACTIONS.GET_LOGGED_IN_USER_PROFILE,
      payload: {
        loggedInUser: retrievedUserProfile,
        loggedInUserId: retrievedUserProfile._id
      }
    });
    
    return retrievedUserProfile;
  };
};

/**
 *
 * @param {{userProfileId: string, body: UserProfileType}} params
 * @returns {Function}
 */
export const putUserProfile = (params: {userProfileId: string, body: UserProfileType}) => {
  Logger.info('Calling putUserProfile.');
  const { userProfileId, body } = params;
  
  return async (dispatch: any) => {
    
    dispatch({
      type: ACTIONS.START_USER_PROFILE_UPDATE,
      payload: {
        loggedInUser: body,
        loggedInUserId: userProfileId
      }
    });
    
    const url = `${getServerUrl()}/userprofiles/${userProfileId}`;
    
    const updateParams = {
      method: 'PUT',
      body: body
    };
    
    const updatedUserProfile = await ApiFetchUtils.ApiFetch(url, updateParams);
    
    // await MixpanelAnalytics.setPeopleProperties({
    //   firstName: updatedUserProfile.firstName,
    //   lastName: updatedUserProfile.lastName
    // });
    
    dispatch({
      type: ACTIONS.USER_PROFILE_UPDATED,
      payload: {
        loggedInUser: updatedUserProfile,
        loggedInUserId: updatedUserProfile._id
      }
    });
    
    return updatedUserProfile;
  };
};

/**
 *
 * @param {{userProfileId: string, body: UserProfileType}} params
 * @returns {Function}
 */
export const patchUserProfile = (params: {userProfileId: string, body: UserProfileType}) => {
  Logger.info('Calling patchUserProfile.');
  const { userProfileId, body } = params;
  
  return async (dispatch: any) => {
    
    dispatch({
      type: ACTIONS.START_USER_PROFILE_PATCH,
      payload: {
        loggedInUser: body,
        loggedInUserId: userProfileId
      }
    });
    
    const url = `${getServerUrl()}/userprofiles/${userProfileId}`;
    
    const patchParams = {
      method: 'PATCH',
      body: body
    };
    
    const patchedUserProfile = await ApiFetchUtils.ApiFetch(url, patchParams);
    
    dispatch({
      type: ACTIONS.SUCCESS_USER_PROFILE_PATCH,
      payload: {
        loggedInUser: patchedUserProfile,
        loggedInUserId: userProfileId
      }
    });
    
    return patchedUserProfile;
  };
};

export const initializeAuth = () => {

  return async (dispatch: any) => {
  
    const computedPayload = {
      isAuthenticated: AuthService.isLoggedIn(),
      isAdmin: AuthService.isAdmin(),
      loggedInUserId: AuthService.getUserId(),
      idToken: AuthService.getToken(),
      expirationTime: AuthService.getTokenExpiration(),
      loggedInUser: {}
    };
    
    if(computedPayload.isAuthenticated && computedPayload.loggedInUserId) {
      const url = `${getServerUrl()}/userprofiles/${computedPayload.loggedInUserId}`;
      computedPayload.loggedInUser = await ApiFetchUtils.ApiFetch(url);
    }
    
    dispatch({
      type: ACTIONS.AUTH_INITIALIZED,
      payload: computedPayload
    })
  };
};
