//@flow
import { ACTIONS } from '../ActionTypes';
import type { ActionType, PaymentMethodsStateType } from '../types';

const getInitialState = (): PaymentMethodsStateType => {
  return {
    byUserProfileId: {}
  };
};


export default (state: PaymentMethodsStateType = getInitialState(), action: ActionType) => {
  let newByUserProfileId;
  switch(action.type) {
    case ACTIONS.GET_PAYMENT_METHODS_FOR_USER: {
      newByUserProfileId = {...state.byUserProfileId};
      const { userProfileId, paymentMethods } = action.payload;
      
      newByUserProfileId[userProfileId] = paymentMethods;
      
      return {
        ...state,
        byUserProfileId: newByUserProfileId
      };
    }
    
    case ACTIONS.ADD_PAYMENT_METHOD: {
      newByUserProfileId = {...state.byUserProfileId};
      const { userProfileId, paymentMethod } = action.payload;
  
      if(!(userProfileId in newByUserProfileId)) {
        newByUserProfileId[userProfileId] = [];
      }
      
      console.log(`newByUserProfileId[userProfileId]: ${JSON.stringify(newByUserProfileId[userProfileId])}`);
      
      newByUserProfileId[userProfileId] = [
        ...newByUserProfileId[userProfileId],
        paymentMethod
      ];
  
      return {
        ...state,
        byUserProfileId: newByUserProfileId
      };
    }
    
    case ACTIONS.REMOVE_PAYMENT_METHOD: {
      newByUserProfileId = {...state.byUserProfileId};
      const { userProfileId, paymentMethodSourceId } = action.payload;
  
      const paymentMethods = newByUserProfileId[userProfileId] || [];
      const newPaymentMethods = [];
      paymentMethods.forEach(paymentMethod => {
        if(paymentMethod.id !== paymentMethodSourceId) {
          newPaymentMethods.push(paymentMethod);
        }
      });
  
      newByUserProfileId[userProfileId] = newPaymentMethods;
  
      return {
        ...state,
        byUserProfileId: newByUserProfileId
      };
    }
    
    default:
      return state;
  }
};
