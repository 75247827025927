import React, { lazy, Suspense } from 'react';

const Profile = lazy(() => import('./Profile').then(module => ({ default: module.Profile })));

const LoadingProfile = () => {
  
  return <div>Loading...</div>
};

const SuspensedProfile = (props) => {
  
  return (
    <Suspense fallback={<LoadingProfile />}>
      <Profile {...props} />
    </Suspense>
  );
};

export { SuspensedProfile as Profile };
