//@flow
class Logger {
  
  /**
   *
   * @param {string} message
   */
  static info(message: string) {
    console.log(message);
  }
  
  /**
   *
   * @param {string} message
   */
  static warn(message: string) {
    console.warn(message);
  }
  
  /**
   *
   * @param {string} message
   * @param {Error|null} error
   */
  static error(message: string, error?: ?Error = null) {
    
    let computedMessage = message;
    if(error) {
      computedMessage = `${message} [ErrorName: ${error.name}] [ErrorMessage: ${error.message}]`;
    }
  
    console.error(computedMessage);
  }
}


export { Logger };
