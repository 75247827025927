//@flow
import { getItem } from '../services/LocalStorageService';
import type { StateType } from '../types';


import { getInitialState } from '../reducers/Restaurants';

export const REDUX_STATE_KEY = 'reduxState';

/**
 *
 * @return {StateType}
 */
export const loadStateFromLocalStorage = (): StateType => {
  const retrieveStateString = getItem(REDUX_STATE_KEY);
  
  const persistedState: StateType = retrieveStateString ? JSON.parse(retrieveStateString) : {};
  
  persistedState.restaurants = {
    ...getInitialState(),
    ...persistedState.restaurants
  };
  
  
  return persistedState;
};
