//@flow
import { ACTIONS } from '@foodbuzzer/foodbuzzer-react-shared/constants';
import type { ActionType, OrderType, OrdersStateType } from '../types';

const getInitialState = (): OrdersStateType => {
  return {
    byId: {},
    byRestaurantId: {},
    byUserProfileId: {},
    filteredById: {}
  };
};


const OrdersReducer = (state: OrdersStateType = getInitialState(), action: ActionType) => {
  let newById;
  let newByRestaurantId;
  let newByUserProfileId;
  switch (action.type) {
    case ACTIONS.GET_MANY_ORDERS: {
      newById = { ...state.byId };
      newByRestaurantId = { ...state.byRestaurantId };
      newByUserProfileId = { ...state.byUserProfileId };
      
      action.payload.orders.forEach((order: OrderType) => {
        newById[order._id] = order;
        
        if(!(order.restaurantId in newByRestaurantId)) {
          newByRestaurantId[order.restaurantId] = {}
        }
        
        newByRestaurantId[order.restaurantId][order._id] = order;
  
        if(!(order.userProfileId in newByUserProfileId)) {
          newByUserProfileId[order.userProfileId] = {}
        }
  
        newByUserProfileId[order.userProfileId][order._id] = order;
      });
      
      return {
        ...state,
        byId: newById,
        byRestaurantId: newByRestaurantId,
        byUserProfileId: newByUserProfileId
      };
    }
    
    case ACTIONS.GET_FILTERED_ORDERS: {
      newById = {};
      newByRestaurantId = {...state.byRestaurantId};
      newByUserProfileId = {...state.byUserProfileId};
      action.payload.orders.forEach(order => {
        newById[order._id] = order;
        
        if(!(order.restaurantId in newByRestaurantId)) {
          newByRestaurantId[order.restaurantId] = {}
        }
        
        newByRestaurantId[order.restaurantId][order._id] = order;
  
        if(!(order.userProfileId in newByUserProfileId)) {
          newByUserProfileId[order.userProfileId] = {}
        }
  
        newByUserProfileId[order.userProfileId][order._id] = order;
      });
      
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById
        },
        byRestaurantId: newByRestaurantId,
        byUserProfileId: newByUserProfileId,
        filteredById: newById
      };
    }
    
    case ACTIONS.GET_ORDER:
    case ACTIONS.PUT_ORDER_SUCCESS:
    case ACTIONS.POST_ORDER_SUCCESS: {
      const { order } = action.payload;
      newById = {
        ...state.byId,
        [order._id]: order
      };
      
      newByRestaurantId = {...state.byRestaurantId};
      newByUserProfileId = {...state.byUserProfileId};
      
      if(!(order.restaurantId in newByRestaurantId)) {
        newByRestaurantId[order.restaurantId] = {}
      }
      newByRestaurantId[order.restaurantId][order._id] = order;
  
      if(!(order.userProfileId in newByUserProfileId)) {
        newByUserProfileId[order.userProfileId] = {}
      }
      newByUserProfileId[order.userProfileId][order._id] = order;
      
      return {
        ...state,
        byId: newById,
        byRestaurantId: newByRestaurantId,
        byUserProfileId: newByUserProfileId
      }
    }
    
    case ACTIONS.CLEAR_SEARCHED_ORDERS: {
      return {
        ...state,
        filteredById: {}
      };
    }
    
    default:
      return state;
  }
};

export { OrdersReducer };
