//@flow

/**
 *
 * @returns {string}
 */
export const getServerUrl = ():string => {

  return process.env.REACT_APP_SERVER_URL || '';
};

/**
 *
 * @returns {string}
 */
export const getAlgoliaAppId = (): string => {
  
  return process.env.REACT_APP_ALGOLIA_APP_ID || '';
};

/**
 *
 * @returns {string}
 */
export const getAlgoliaClientId = (): string => {
  
  return process.env.REACT_APP_ALGOLIA_CLIENT_ID || '';
};

/**
 *
 * @returns {string}
 */
export const getAuth0Domain = (): string => {
  
  return process.env.REACT_APP_AUTH0_DOMAIN || '';
};

/**
 *
 * @returns {string}
 */
export const getAuth0ClientId = (): string => {
  
  return process.env.REACT_APP_AUTH0_CLIENT_ID || '';
};

/**
 *
 * @returns {string}
 */
export const getClientUrl = (): string => {
  
  return process.env.REACT_APP_CLIENT_URL || '';
};

/**
 *
 * @returns {string}
 */
export const getStripePublishableKey = (): string => {
  
  return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
};

/**
 *
 * @return {string}
 */
export const getAuth0SilentCallbackUrl = (clientUrl: string): string => {
  
  return `${clientUrl}/${process.env.REACT_APP_AUTH0_SILENT_PAGE || ''}`;
};
