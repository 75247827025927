//@flow
import { ACTIONS as ImportedActions } from '@foodbuzzer/foodbuzzer-react-shared/constants'

export const ACTIONS = {
  ...ImportedActions,
  
  // GET_FILTERED_RESTAURANTS: 'GET_FILTERED_RESTAURANTS',
  // GET_MANY_RESTAURANTS: 'GET_MANY_RESTAURANTS',
  // GET_RESTAURANT: 'GET_RESTAURANT',
  // POST_RESTAURANT_START: 'POST_RESTAURANT_START',
  // POST_RESTAURANT_SUCCESS: 'POST_RESTAURANT_SUCCESS',
  // PUT_RESTAURANT_START: 'PUT_RESTAURANT_START',
  // PUT_RESTAURANT_SUCCESS: 'PUT_RESTAURANT_SUCCESS',
  // CLEAR_SEARCHED_RESTAURANTS: 'CLEAR_SEARCHED_RESTAURANTS',
  //
  //
  // GET_FILTERED_MENU_ITEMS: 'GET_FILTERED_MENU_ITEMS',
  // GET_MANY_MENU_ITEMS: 'GET_MANY_MENU_ITEMS',
  // GET_MENU_ITEM: 'GET_MENU_ITEM'
  
  SET_ACTIVE_TEMPORARY_ORDER: 'SET_ACTIVE_TEMPORARY_ORDER',
  CREATE_TEMPORARY_ORDER: 'CREATE_TEMPORARY_ORDER',
  UPDATE_TEMPORARY_ORDER: 'UPDATE_TEMPORARY_ORDER',
  DELETE_TEMPORARY_ORDER: 'DELETE_TEMPORARY_ORDER',
  
  GET_PAYMENT_METHODS_FOR_USER: 'GET_PAYMENT_METHODS_FOR_USER',
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  REMOVE_PAYMENT_METHOD: 'REMOVE_PAYMENT_METHOD',
  
  SET_CURRENT_RESTAURANT_ID: 'SET_CURRENT_RESTAURANT_ID',
  LOGIN_ERROR: 'LOGIN_ERROR',
  
  GET_LOGGED_IN_USER_PROFILE: 'GET_LOGGED_IN_USER_PROFILE',
  START_USER_PROFILE_UPDATE: 'START_USER_PROFILE_UPDATE',
  USER_PROFILE_UPDATED: 'USER_PROFILE_UPDATED',
  START_USER_PROFILE_PATCH: 'START_USER_PROFILE_PATCH',
  SUCCESS_USER_PROFILE_PATCH: 'SUCCESS_USER_PROFILE_PATCH',
  
  GET_FILTERED_RESTAURANT_LOCATIONS: 'GET_FILTERED_RESTAURANT_LOCATIONS',
  GET_MANY_RESTAURANT_LOCATIONS: 'GET_MANY_RESTAURANT_LOCATIONS',
  GET_RESTAURANT_LOCATION: 'GET_RESTAURANT_LOCATION',
  POST_RESTAURANT_LOCATION_START: 'POST_RESTAURANT_LOCATION_START',
  POST_RESTAURANT_LOCATION_SUCCESS: 'POST_RESTAURANT_LOCATION_SUCCESS',
  PUT_RESTAURANT_LOCATION_START: 'PUT_RESTAURANT_LOCATION_START',
  PUT_RESTAURANT_LOCATION_SUCCESS: 'PUT_RESTAURANT_LOCATION_SUCCESS',
  
  GET_COUPON: 'GET_COUPON'
};
