//@flow
import { ACTIONS } from '../ActionTypes';
import type { ActionType, TemporaryOrdersStateType } from '../types';

const getInitialState = (): TemporaryOrdersStateType => {
  
  return {
    activeOrderId: '', //this is old and no one should be using it anymore
    byId: {},
    byGroupOrderId: {},
    restaurantId: ''
  };
};


export default (state: TemporaryOrdersStateType = getInitialState(), action: ActionType) => {

  let newById;
  let newByGroupOrderId;
  
  switch(action.type) {
    
    case ACTIONS.SET_ACTIVE_TEMPORARY_ORDER: {
      const { activeOrderId } = action.payload;
  
      return {
        ...state,
        activeOrderId,
      };
    }
   
    case ACTIONS.SET_CURRENT_RESTAURANT_ID: {
      const { restaurantId } = action.payload;
      
      return {
        ...state,
        restaurantId: restaurantId
      };
    }
    
    case ACTIONS.CREATE_TEMPORARY_ORDER:
    case ACTIONS.UPDATE_TEMPORARY_ORDER:
      const { order } = action.payload;
  
      newById = { ...state.byId };
      newById[order._id] = order;
      
      newByGroupOrderId = { ...state.byGroupOrderId };
      if(order.groupOrderId) {
        
        if(!(order.groupOrderId in newByGroupOrderId)) {
          newByGroupOrderId[order.groupOrderId] = {};
        }
        
        newByGroupOrderId[order.groupOrderId][order._id] = order;
      }
      
      return {
        ...state,
        byId: newById,
        byGroupOrderId: newByGroupOrderId
      };
      
    case ACTIONS.DELETE_TEMPORARY_ORDER: {
      const {orderId, groupOrderId = ''} = action.payload;
  
      newById = {...state.byId};
      delete newById[orderId];
      
      newByGroupOrderId = { ...state.byGroupOrderId };
      if(groupOrderId && groupOrderId in newByGroupOrderId) {
        delete newByGroupOrderId[groupOrderId][orderId];
      }
  
      return {
        ...state,
        byId: newById,
        byGroupOrderId: newByGroupOrderId
      };
    }
    case ACTIONS.LOGOUT_SUCCESS: {
    
      return getInitialState();
    }
    
    default:
      return state;
  }
};
