import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { loginCallback } from '../../actions';
import type { UserProfileType } from '../../types';

type AuthCallbackProps = {
  loginCallback: () => Promise<{successfulLogin: boolean, nextPath?: string, userProfile?: UserProfileType}>;
}

class AuthCallback extends PureComponent<AuthCallbackProps> {

  static mapDispatchToProps = (dispatch: any) => {
  
    return {
      loginCallback: () => dispatch(loginCallback())
    };
  };
  
  async componentDidMount() {
  
    const result = await this.props.loginCallback();
    
    if(result.successfulLogin) {
      this.props.history.push(result.nextPath);
    }
  }
  
  render() {
  
    return (
      <div>
        Loading...
      </div>
    );
  }
  
}

const ConnectedAuthCallback = connect(null, AuthCallback.mapDispatchToProps)(AuthCallback);

export {
  ConnectedAuthCallback as AuthCallback
};
