//@flow
import React, { lazy, Suspense } from 'react';

const EditRestaurantSchedule = lazy(() => import('./EditRestaurantSchedule').then(module => ({ default: module.EditRestaurantSchedule })));

const LoadingEditRestaurantSchedule = () => {
  
  return <div>Loading...</div>
};

const SuspensedEditRestaurantSchedule = (props: any) => {
  
  return (
    <Suspense fallback={<LoadingEditRestaurantSchedule />}>
      <EditRestaurantSchedule {...props} />
    </Suspense>
  );
};

export { SuspensedEditRestaurantSchedule as EditRestaurantSchedule };
