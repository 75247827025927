//@flow
import React, { lazy, Suspense } from 'react';

const EditRestaurantDetails = lazy(() => import('./EditRestaurantDetails').then(module => ({ default: module.EditRestaurantDetails })));

const LoadingEditRestaurantDetails = () => {
  
  return <div>Loading...</div>
};

const SuspensedEditRestaurantDetails = (props: any) => {
  
  return (
    <Suspense fallback={<LoadingEditRestaurantDetails />}>
      <EditRestaurantDetails {...props} />
    </Suspense>
  );
};

export { SuspensedEditRestaurantDetails as EditRestaurantDetails };
