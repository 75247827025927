import { combineReducers } from 'redux';

import { AuthReducer } from './Auth';
import { CouponReducer } from './Coupons';
import MenuItemsReducer from './MenuItems';
import { OrdersReducer } from './Orders';
import PaymentMethodsReducer from './PaymentMethods';
import RestaurantsReducer from './Restaurants';
import temporaryOrderReducer from './TemporaryOrders';
import restaurantLocationReducer from './RestaurantLocations';


const reducers = combineReducers({
  auth: AuthReducer,
  coupons: CouponReducer,
  incompleteOrders: temporaryOrderReducer,
  menuItems: MenuItemsReducer,
  orders: OrdersReducer,
  paymentMethods: PaymentMethodsReducer,
  restaurantLocations: restaurantLocationReducer,
  restaurants: RestaurantsReducer,
});

export default reducers;
