//@flow
import React from 'react';
import { isEmpty } from 'lodash';

import * as OrderUtils from '../../utils/OrderUtils';
import * as NumberUtils from '@foodbuzzer/foodbuzzer-shared/utils/NumberUtils';

import type { OrderMenuItemType } from '../../types';

import styles from './CheckoutMenuItem.module.scss';

type CheckoutMenuItemProps = {
  orderMenuItem: OrderMenuItemType;
  onOrderMenuItemDelete: (id: string) => Promise<void>;
  onEditMenuItem: ({orderMenuItem: OrderMenuItemType}) => void;
};

const CheckoutMenuItem = (props: CheckoutMenuItemProps) => {
  const { orderMenuItem, onOrderMenuItemDelete, onEditMenuItem } = props;
  
  if(isEmpty(orderMenuItem)) {
    return null;
  }
  
  const { menuItem, quantity, notes = '', selectedChoices = {} } = orderMenuItem;
  const { choices } = menuItem;
  
  let renderedNotesSection = null;
  if(!isEmpty(notes)) {
    renderedNotesSection = (<div className={styles.subtitle}>{notes}</div>);
  }
  
  let additionalChoicePrice: number = 0;
  let renderedChoices = null;
  if(!isEmpty(selectedChoices)) {
    const choiceIds = Object.keys(selectedChoices);
    renderedChoices = choiceIds.map((choiceId: string) => {
      const selectedOption = selectedChoices[choiceId];
      const menuItemChoice = choices[choiceId];
      const optionDataItems = OrderUtils.getSelectedChoiceData(menuItemChoice, selectedOption);
      
      if(isEmpty(optionDataItems)) {
        return null;
      }
      
      const itemArray = optionDataItems.map((optionData) => {
        let displayOptionPrice = '';
        if(optionData.optionPrice > 0) {
          displayOptionPrice = `(${NumberUtils.convertCentsToUSD(optionData.optionPrice)})`;
        }
        additionalChoicePrice += optionData.optionPrice;
        
        return `${optionData.optionText}${displayOptionPrice}`;
        //return <Text key={optionData.optionId} style={MenuItemStyles.subtitle}>{optionData.optionText}{displayOptionPrice}</Text>;
      });
      
      return <div key={'items'} className={styles.subtitle}>{itemArray.join(', ')}</div>;
    });
  }
  
  const perMenuItemTotal = menuItem.priceWithoutTax + additionalChoicePrice;
  
  let boundOnEditMenuItem = null;
  if(onEditMenuItem) {
    boundOnEditMenuItem = onEditMenuItem.bind(this, orderMenuItem);
  }
  
  let boundOnOrderMenuItemDelete = null;
  if(onOrderMenuItemDelete) {
    boundOnOrderMenuItemDelete = onOrderMenuItemDelete.bind(this, orderMenuItem.id);
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer} onClick={boundOnEditMenuItem}>
        <div className={styles.quantityContainer}>
          <div className={styles.quantity}>{quantity}</div>
          <div className={styles.itemEdit}>edit</div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{menuItem.name}</div>
          { renderedChoices }
          { renderedNotesSection }
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.price}>{NumberUtils.convertCentsToUSD(perMenuItemTotal * quantity)}</div>
        </div>
      </div>
      <div className={styles.delete} onClick={boundOnOrderMenuItemDelete}>Remove</div>
    </div>
  );
};

export { CheckoutMenuItem };
