import React from 'react';
import ContentLoader from 'react-content-loader';



const SearchResultItemLoader = (props) => {

  return (
    <ContentLoader height={80}>
      {/* Only SVG shapes */}
      <rect x="8.92" y="19" rx="0" ry="0" width="60" height="60" />
      <rect x="85.91" y="19" rx="0" ry="0" width="190" height="20" />
      <rect x="88.91" y="47" rx="0" ry="0" width="166" height="8" />
      <rect x="87.91" y="61" rx="0" ry="0" width="166" height="8" />
      <rect x="87.91" y="75" rx="0" ry="0" width="50" height="8" />
    </ContentLoader>
  );
};


export { SearchResultItemLoader };
