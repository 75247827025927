import queryString from 'query-string';
import { Logger } from '../utils/LoggerService';
import { ACTIONS } from '../ActionTypes';
import { ApiFetchUtils } from '@foodbuzzer/foodbuzzer-react-shared/utils';

import { getServerUrl } from '../utils/ConfigUtils';

import type { RestaurantLocationType } from '../types';



/**
 *
 * @param {Object} query
 * @return {function(*)}
 */
export const getFilteredRestaurantLocations = (query) => {
  return _getManyRestaurantLocations(query, ACTIONS.GET_FILTERED_RESTAURANT_LOCATIONS);
};

/**
 *
 * @param {Object} params
 * @return {function(*)}
 */
export const getManyRestaurantLocations = (params) => {
  return _getManyRestaurantLocations(params, ACTIONS.GET_MANY_RESTAURANT_LOCATIONS);
};

/**
 *
 * @param {Object} params
 * @param {String} actionType
 * @return {function(*)}
 * @private
 */
let _getManyRestaurantLocations = (params, actionType) => {
  let newQuery;
  const baseUrl = `${getServerUrl()}/restaurantlocations`;
  let url = baseUrl;
  
  if(params) {
    let localQueryObject = {};
    Object.keys(params).forEach(item => {
      let itemValue = params[item];
      if(Array.isArray(itemValue)) {
        localQueryObject[item] = itemValue.join(',');
      } else {
        localQueryObject[item] = itemValue;
      }
    });
    
    newQuery = queryString.stringify(localQueryObject);
    
    if(newQuery.length > 0) {
      url = `${baseUrl}?${newQuery}`;
    }
  }
  
  return async (dispatch) => {
    
    const retrievedRestaurantLocations = await ApiFetchUtils.ApiFetch(url);
    
    dispatch({
      type: actionType,
      payload: {
        restaurantLocations: retrievedRestaurantLocations
      }
    });
    
    return retrievedRestaurantLocations;
  };
};

/**
 *
 * @param {Object} params
 * @param {string} params.restaurantLocationId
 * @returns {function(*)}
 */
export const getRestaurantLocation = (params) => {
  const {restaurantLocationId} = params;
  
  const url = `${getServerUrl()}/restaurantlocations/${restaurantLocationId}`;
  
  return async (dispatch) => {
    const retrievedRestaurantLocation = await ApiFetchUtils.ApiFetch(url);
    
    dispatch({
      type: ACTIONS.GET_RESTAURANT_LOCATION,
      payload: {
        restaurantLocation: retrievedRestaurantLocation
      }
    });
    
    return retrievedRestaurantLocation;
  };
};

/**
 *
 * @param {Object} params
 * @param {RestaurantLocationType} params.body
 * @returns {function(*)}
 */
export const postRestaurantLocation = (params: {body: RestaurantLocationType }) => {
  const { body } = params;
  
  return async (dispatch) => {
    dispatch({
      type: ACTIONS.POST_RESTAURANT_LOCATION_START,
      payload: {
        restaurantLocation: body
      }
    });
    const createdRestaurantLocation = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/restaurantlocations`, {
      method: 'POST',
      body: body
    });
    
    dispatch({
      type: ACTIONS.POST_RESTAURANT_LOCATION_SUCCESS,
      payload: {
        restaurantLocation: createdRestaurantLocation
      }
    });
    
    return createdRestaurantLocation;
  };
};

/**
 *
 * @param {Object} params
 * @param {string} params.restaurantLocationId
 * @param {RestaurantLocationType} params.body
 * @returns {function(*)}
 */
export const putRestaurantLocation = (params: {restaurantLocationId: string, body: RestaurantLocationType}) => {
  const { restaurantLocationId, body } = params;
  
  Logger.info(`Calling putRestaurantLocation. [RestaurantLocationId: ${restaurantLocationId}]`);
  
  return async (dispatch: any) => {
    dispatch({
      type: ACTIONS.PUT_RESTAURANT_LOCATION_START,
      payload: {
        restaurantLocation: body
      }
    });
    
    const updatedRestaurantLocation = await ApiFetchUtils.ApiFetch(`${getServerUrl()}/restaurantlocations/${restaurantLocationId}`, {
      method: 'PUT',
      body: body
    });
    
    dispatch({
      type: ACTIONS.PUT_RESTAURANT_LOCATION_SUCCESS,
      payload: {
        restaurantLocation: updatedRestaurantLocation
      }
    });
    
    return updatedRestaurantLocation;
  };
};

export const deleteRestaurantLocation = (params: Object) => {
  
  return async (dispatch) => {
  
  };
};
