//import 'cross-fetch/polyfill';
import Auth0 from 'auth0-js';
import Base64 from 'base-64';
import decode from 'jwt-decode';
import {Promise as BluebirdPromise} from 'bluebird';
import { isNil, isEmpty, isString, get } from 'lodash';

import { getAuth0Domain, getAuth0ClientId, getClientUrl, getAuth0SilentCallbackUrl } from '../utils/ConfigUtils';
import * as LocalStorageService from './LocalStorageService';
import { Logger } from '../utils/LoggerService';


const RESPONSE_TYPE: string = 'id_token token';
const LOGIN_TOKEN_SCOPE: string = 'openid profile email offline_access';

class AuthService {
  
  auth0Client: any;
  
  constructor() {
    console.log(`getAuth0Domain(): ${getAuth0Domain()} || getAuth0ClientId(): ${getAuth0ClientId()}`);
    this.auth0Client = new Auth0.WebAuth({
      domain: getAuth0Domain(),
      clientID: getAuth0ClientId()
    });
  }
  
  /**
   *
   * @returns {Promise}
   */
  webAuthAuthorize() {
    return this.auth0Client.webAuth.authorize({
      scope: LOGIN_TOKEN_SCOPE,
      audience: `https://${getAuth0Domain()}/userinfo`
    });
  }
  
  /**
   *
   * @param params
   * @param {String} params.email
   * @param {String} params.password
   * @param {String} [params.targetPath='']
   * @param onError
   */
  async login(params) {
    // redirects the call to auth0 instance
    const { email, password, targetPath = '' } = params;
    
    let redirectUri = `${getClientUrl()}/auth/callback`;
    const loginParams = {
      connection: 'Username-Password-Authentication',
      username: email,
      password,
      responseType: RESPONSE_TYPE,
      redirectUri,
      scope: LOGIN_TOKEN_SCOPE,
    };
    
    if (targetPath) {
      const stateParams = {
        targetPath,
      };
      loginParams.state = Base64.encode(JSON.stringify(stateParams));
    }
    
    await new BluebirdPromise((resolve, reject) => {
      this.auth0Client.redirect.loginWithCredentials(loginParams, (error, authResult) => {
        // Logger.info(`error.error: ${error.error}, ${error.error_description}`);
        // Logger.error(`Unexpected error in login. [Error: ${error.error}] [Message: ${error.error_description}]`);
        if(error) {
          //throw new Error();
          reject(new Error(error.error_description));
        } else {
          resolve();
        }
      });
    });
  }
  
  // /**
  //  *
  //  * @param {Object} params
  //  * @param {String} params.email
  //  * @param {String} params.password
  //  * @param {String} [params.targetPath='']
  //  * @return {Promise<void>}
  //  */
  // async login(params: {email: string, password: string}) {
  //   //redirects the call to auth0 instance
  //   const {email, password} = params;
  //
  //   return await this.auth0Client.auth.passwordRealm({
  //     username: email,
  //     password: password,
  //     realm: 'Username-Password-Authentication',
  //     scope: LOGIN_TOKEN_SCOPE
  //   });
  // };
  
  /**
   *
   * @returns {Promise<void>}
   */
  async logout() {
    console.log(`logout`);
    // if (Platform.OS !== 'android') {
    //   await this.auth0Client.webAuth.clearSession({});
    // }
    
    await Promise.all([AuthService.clearRefreshToken(), AuthService.clearToken()]);
  }
  
  /**
   *
   * @param {string} hash
   */
  parseInfo(hash: string) {
    Logger.info('Calling AuthService.parseInfo');
    return new BluebirdPromise((resolve, reject) => {
      
      return this.parseHash({hash}, (err, authResult: any) => {
        if (err) {
          return console.log(err);
        }
        
        console.log(authResult);
        
        const {accessToken, idToken, state} = authResult;
        
        let decodedState = null;
        if(state) {
          //to object?
          let urlDecodedState;
          let stringState;
          try {
            //urldecode
            urlDecodedState = decodeURIComponent(state);
  
            //base64 decode
            stringState = Base64.decode(urlDecodedState);
            
            decodedState = JSON.parse(stringState);
          } catch(err) {
            decodedState = stringState || urlDecodedState || state;
          }
        }
        
        return this.getUserInfo(accessToken).then(profile => {
          return resolve({profile, idToken, state: decodedState});
        }).catch(err => {
          return reject(err);
        });
      });
    });
  }
  
  /**
   *
   * @param {Object} socialLoginParams
   * @param {string} socialLoginParams.targetPath Path that the user will be redirected to after login
   * @return {*}
   */
  googleLogin(socialLoginParams: Object) {
    return this._socialLogin('google-oauth2', socialLoginParams);
  }
  
  /**
   *
   * @param {Object} socialLoginParams
   * @param {String} socialLoginParams.targetPath Path that the user will be redirected to after login
   * @return {*}
   */
  facebookLogin(socialLoginParams: Object) {
    return this._socialLogin('facebook', socialLoginParams);
  }
  
  
  /**
   *
   * @param {string} connection
   * @param {Object} socialLoginParams
   * @param {string} socialLoginParams.targetPath Path that the user will be redirected to after login
   * @return {Promise}
   */
  _socialLogin(connection, socialLoginParams = {}) {
    const { targetPath = ''} = socialLoginParams;
    
    //redirects the call to auth0 instance
    const loginParams = {
      connection: connection,
      responseType: RESPONSE_TYPE,
      redirectUri: `${getClientUrl()}/auth/callback`,
      scope: LOGIN_TOKEN_SCOPE
    };
  
    if (targetPath) {
      const stateParams = {
        targetPath
      };
      loginParams.state = Base64.encode(JSON.stringify(stateParams));
    }
    
    return this.auth0Client.authorize(loginParams);
  }
  
  /**
   *
   * @param {string} email
   * @return {Promise<void>}
   */
  async forgotPassword(email: string) {
    try {
      const forgotPasswordParameters = {
        connection: 'Username-Password-Authentication',
        email
      };
      
      return await this.auth0Client.auth.resetPassword(forgotPasswordParameters);
    } catch(error) {
      console.log(`Unexpected error AuthService.forgotPassword. [Error: ${error.name}] [Message: ${error.message}] [Error: ${error.error}]`)
    }
  }
  
  /**
   *
   * @param {Object} options
   * @param {string} options.hash
   * @param {Function} callback
   */
  parseHash(options: {hash: string}, callback: () => {}) {
    this.auth0Client.parseHash(options, callback);
  }
  
  /**
   * Retrieves userInfo from Auth0
   * @param {string} accessToken
   * @returns {Promise}
   */
  getUserInfo(accessToken: string) {
    
    return new BluebirdPromise((resolve, reject) => {
      this.auth0Client.client.userInfo(accessToken, (err, result) => {
        if(err) { reject(err); }
        else {
          resolve(result);
        }
      });
    });
    
  }
  
  /**
   * Decodes the idToken
   * @param {string} token
   * @return {Object}
   */
  static decodeToken(token: string) {
    return decode(token);
  }
  
  /**
   *
   * @param {string} idToken
   * @returns Promise<Object> Object contains token_type, expires_in, and id_token
   */
  renewToken(idToken: string): {} {
    return new BluebirdPromise((resolve, reject) => {
      
      const params = {
        id_token: idToken,
        scope: LOGIN_TOKEN_SCOPE,
        redirectUri: `${getClientUrl()}/auth/callback`,
        usePostMessage: false
      };
      
      return this.auth0Client.renewAuth(params, (err, response) => {
        console.log(`Renew Token. Error? ${JSON.stringify(err)} || Response? ${JSON.stringify(response)}`);
        
        if(err) reject(err);
        else resolve(response);
      });
    });
  }
  
  /**
   * Returns an accessToken by using the refreshToken
   * @param {String} refreshToken
   * @returns {Promise.<*>}
   */
  async refreshToken(refreshToken: string) {
    try {
      const params = {
        refreshToken,
        scope: LOGIN_TOKEN_SCOPE
      };
      
      return await this.auth0Client.auth.refreshToken(params);
    } catch(error) {
      if(error.name === 'invalid_grant') {
        console.error(`Unexpected error refreshToken. [${error.name}] [Error: ${error.message}]`);
      } else {
        throw error;
      }
    }
    
    return {};
  }
  
  // static randomString(length) {
  //   const result = [];
  //   const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  //   for (let itemCount = 0; itemCount < length; itemCount += 1) {
  //     result.push(charset[random(1, 1000) % charset.length]);
  //   }
  //   return result.join('');
  // }
  
  /**
   *
   * @returns {Promise<void>}
   */
  async renewTokens(): Promise<void> {
    try {
      Logger.info('Calling AuthService.renewTokens');
  
      // console.log(`protocol: ${window.location.protocol}`);
      // console.log(`host: ${window.location.host}`);
      // console.log(`hostname: ${window.location.hostname}`);
  
      const params = {
        responseType: RESPONSE_TYPE,
        scope: LOGIN_TOKEN_SCOPE,
        redirectUri: getAuth0SilentCallbackUrl(`${window.location.protocol}//${window.location.host}`),
        usePostMessage: true,
        postMessageDataType: 'auth0:silent-authentication'
      };
      
      return await new BluebirdPromise((resolve, reject) => {
        this.auth0Client.checkSession(params, (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            resolve(authResult);
          } else if (err) {
            console.log(`${err.error}, ${err.error_description}`);
            reject(err);
          } else {
            resolve({});
          }
        });
      });
      
      
    } catch(err) {
      Logger.error(`Unexpected error in renewTokens.`, err);
      throw err;
    }
  
  }
  
  /**
   *
   * @param {string} token
   * @returns {Date|null}
   */
  static getTokenExpirationDate = (token: string): Date|null => {
    if(isNil(token) || isEmpty(token) || !isString(token)) {
      return null;
    }
    
    const decoded: {exp: number} = decode(token);
    if(!decoded.exp) {
      return null;
    }
    
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  };
  
  static getUserIdFromToken = (token: string): string => {
  
    const decoded = decode(token);
  
  
    const result = get(decoded, '["https://getquickbite.com/app_metadata"].userId', '');
  
    return result;
  };
  
  /**
   *
   * @param {string} token
   * @returns {*}
   */
  static getTokenExpirationUtc = (token: string): number => {
    if(isNil(token) || isEmpty(token) || !isString(token)) {
      return 0;
    }
    
    const decoded: {exp: number} = decode(token);
    if(!decoded.exp) {
      return 0;
    }
    
    return decoded.exp;
  };
  
  /**
   *
   * @returns {Promise<boolean>}
   */
  static isLoggedIn(): Promise<boolean> {
    const isTokenExpired = (token) => {
      const date = AuthService.getTokenExpirationDate(token);
      if(date === null) {
        return true;
      }
      
      const currentTime = new Date().valueOf();
  
      console.log(`currentTime: ${currentTime}`);
      console.log(`date.valueOf(): ${date.valueOf()}`);
      
      return !(date.valueOf() > currentTime);
    };
    
    // Checks if there is a saved token and it's still valid
    const token = AuthService.getToken();
    const result =  !!token && !isTokenExpired(token);
    
    console.log(`IsLoggedIn Result: ${result}`);
    return result;
  }
  
  /**
   *
   * @returns {number}
   */
  static getTokenExpiration(): number {
    const isLoggedIn = AuthService.isLoggedIn();
    if(!isLoggedIn) {
      return -1;
    }
  
    const token = AuthService.getToken();
    if(isEmpty(token)) {
      return -1;
    }
    
    return AuthService.getTokenExpirationUtc(token);
  }
  
  /**
   *
   * @returns {boolean}
   */
  static isAdmin(): boolean {
  
    const isLoggedIn = AuthService.isLoggedIn();
    if(!isLoggedIn) {
      return false;
    }
  
    const token = AuthService.getToken();
    if(isEmpty(token)) {
      return false;
    }
  
    const decoded = decode(token);
    
    
    const result: boolean = get(decoded, '["https://getquickbite.com/app_metadata"].isAdmin', false);
    
    return result;
  }
  
  /**
   *
   * @param {string} userId
   * @returns {Promise<void>}
   */
  static setUserId(userId: string) {
    // Saves profile data to localStorage
    return LocalStorageService.setItem('userId', userId);
  }
  
  /**
   *
   * @returns {Promise<*>}
   */
  static getUserId() {
    // Retrieves the profile data from localStorage
    return LocalStorageService.getItem('userId');
  }
  
  /**
   *
   * @returns {Promise<void>}
   */
  static clearToken() {
    return LocalStorageService.removeItem('id_token');
  }
  
  /**
   *
   * @param {string} idToken
   * @returns {Promise<void>}
   */
  static setToken(idToken: string) {
    // Saves user token to local storage
    return LocalStorageService.setItem('id_token', idToken);
  }
  
  /**
   *
   * @returns {Promise<*>}
   */
  static getToken() {
    // Retrieves the user token from local storage
    return LocalStorageService.getItem('id_token');
  }
  
  /**
   *
   * @returns {Promise<void>}
   */
  static clearRefreshToken() {
    return LocalStorageService.removeItem('refreshToken');
  }
  
  /**
   *
   * @param {string} refreshToken
   * @returns {Promise<void>}
   */
  static setRefreshToken(refreshToken: string) {
    // Saves user token to local storage
    return LocalStorageService.setItem('refreshToken', refreshToken);
  }
  
  /**
   *
   * @returns {Promise<*>}
   */
  static getRefreshToken() {
    // Retrieves the user token from local storage
    return LocalStorageService.getItem('refreshToken');
  }
  
}


export { AuthService };
