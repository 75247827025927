//@flow
import React, { useCallback } from 'react';
import { useMappedState } from 'redux-react-hook';
import { Route, Redirect } from 'react-router-dom';
import type { StateType } from '../types';

type AdminRouteProps = {
  component: any;
}

const AdminRoute = (props: AdminRouteProps) => {
  const { component: Component, ...rest } = props;
  
  // Declare your memoized mapState function
  const mapState = useCallback(
    (state: StateType) => ({
      isAdmin: state.auth.isAdmin,
      isAuthenticated: state.auth.isAuthenticated,
    }), []);
  
  // Get data from and subscribe to the store
  const {isAdmin, isAuthenticated} = useMappedState(mapState);
  
  return (
    <Route
      {...rest}
      render={props =>
        (isAdmin && isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export { AdminRoute };
