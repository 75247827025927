//@flow
import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import cns from 'classnames/bind';

import styles from './QbButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const cx = cns.bind(styles);

type QbButtonProps = {
  className?: any;
  outline?: boolean;
  children?: any;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
}

const QbButton = React.memo<QbButtonProps>((props: QbButtonProps) => {
  const { children, className, outline = false, disabled = false, loading = false, loadingText = '', ...other } = props;
  
  let innerContentSection = children;
  if(loading) {
    innerContentSection = (
      <Fragment>
        <FontAwesomeIcon icon={['fal', 'spinner']} fixedWidth={true} spin={true} />
        {!!loadingText && <span> {loadingText}</span> }
      </Fragment>
    );
  }
  
  return (
    <Button disabled={disabled} className={cx({[styles.button]: !outline}, {[styles.outlineButton]: outline}, className)} {...other}>
      {innerContentSection}
    </Button>
  );
});


export { QbButton };
