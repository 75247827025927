//@flow
import moment from 'moment/moment';
import type Moment from 'moment';
import { get, isEmpty, keyBy, orderBy } from 'lodash';
import tzmoment from 'moment-timezone';
import type {
  RestaurantOpenScheduleItemType,
  RestaurantScheduleOverrideType,
  RestaurantType,
  ScheduleTimeblockType,
} from '../types';

/**
 *
 * @param {RestaurantType} restaurantObject
 * @returns {{isOpenNow: boolean, text: string, timeString: string}}
 */
export const getOpenInfoForTomorrow = (restaurantObject: RestaurantType): {isOpenNow: boolean, text: string, timeString: string, restaurantLocationId: string} => {
  
  const tomorrowTime = moment().add(1, 'd').hour(0).minute(0).second(0);
  
  return _getOpenInfoForDate(tomorrowTime, restaurantObject, true);
};

/**
 *
 * @param {RestaurantType} restaurantObject
 * @returns {{isOpenNow: boolean, text: string, timeString: string}}
 */
export const getOpenInfoForCurrentTime = (restaurantObject: RestaurantType): {isOpenNow: boolean, text: string, timeString: string, restaurantLocationId: string} => {
  const currentTime = moment().utc();
  
  return _getOpenInfoForDate(currentTime, restaurantObject);
};



/**
 *
 * @param {Moment} momentObject
 * @param {RestaurantType} restaurantObject
 * @param {boolean} isFuture
 * @returns {{isOpenNow: boolean, text: string, timeString: string}}
 * @private
 */
export const _getOpenInfoForDate = (momentObject: Moment, restaurantObject: RestaurantType, isFuture: boolean = false): {isOpenNow: boolean, text: string, timeString: string, restaurantLocationId: string} => {
  
  const time = momentObject;
  
  let timezoneCorrectedCurrentTime = time;
  if(restaurantObject.timezone) {
    timezoneCorrectedCurrentTime = tzmoment(time).tz(restaurantObject.timezone);
  }
  
  const scheduleOverrideList = Object.values(restaurantObject.scheduleOverrides || {});
  let scheduleOverrideLookup = {};
  if(!isEmpty(scheduleOverrideList)) {
    scheduleOverrideLookup = keyBy(scheduleOverrideList, 'date');
  }
  
  //parse days
  const yesterdayMomentObject = moment(timezoneCorrectedCurrentTime).add(-1, 'd');
  const currentDayMomentObject = moment(timezoneCorrectedCurrentTime);
  
  const yesterdayScheduleOverrideKey = yesterdayMomentObject.format('YYYY-MM-DD');
  const currentDayScheduleOverrideKey = currentDayMomentObject.format('YYYY-MM-DD');
  
  
  const yesterdayDayOfWeek = yesterdayMomentObject.format('dddd').toLocaleLowerCase();
  const currentDayOfWeek = currentDayMomentObject.format('dddd').toLocaleLowerCase();
  
  let yesterdaySchedule = get(restaurantObject, `openSchedule[${yesterdayDayOfWeek}]`, {});
  let todaySchedule = get(restaurantObject, `openSchedule[${currentDayOfWeek}]`, {});
  
  if(yesterdayScheduleOverrideKey in scheduleOverrideLookup) {
    yesterdaySchedule = scheduleOverrideLookup[yesterdayScheduleOverrideKey];
  }
  
  if(currentDayScheduleOverrideKey in scheduleOverrideLookup) {
    todaySchedule = scheduleOverrideLookup[currentDayScheduleOverrideKey];
  }
  
  let isOpenNow = false;
  let openCloseText = 'Closed';
  let result = {
    isOpenNow: isOpenNow,
    isOpenLaterToday: false,
    text: openCloseText,
    timeString: '',
    restaurantLocationId: ''
  };
  
  if(todaySchedule.isOpen && !isEmpty(todaySchedule.openTime) && !isEmpty(todaySchedule.closeTime)) {
    result = _getOpenDataFromDate(timezoneCorrectedCurrentTime, todaySchedule, isFuture);
  }
  
  if(result.isOpenNow || (isFuture && !isEmpty(result.timeString))) {
    return result;
  }
  
  if(yesterdaySchedule.isOpen && !isEmpty(yesterdaySchedule.openTime) && !isEmpty(yesterdaySchedule.closeTime)) {
    result = _getOpenDataFromDate(timezoneCorrectedCurrentTime, yesterdaySchedule, isFuture);
  }
  
  if(result.isOpenNow) {
    return result;
  }
  
  if(yesterdaySchedule.isOpen && !isEmpty(yesterdaySchedule.timeblocks)) {
    result = _getOpenDataFromTimeblocks(timezoneCorrectedCurrentTime, yesterdaySchedule, yesterdayDayOfWeek, false, isFuture);
  }
  
  if(result.isOpenNow || (isFuture && !isEmpty(result.timeString))) {
    return result;
  }
  
  if(todaySchedule.isOpen && !isEmpty(todaySchedule.timeblocks)) {
    result = _getOpenDataFromTimeblocks(timezoneCorrectedCurrentTime, todaySchedule, currentDayOfWeek, true, isFuture);
  }
  
  return result;
};

/**
 *
 * @param {Moment} timezoneCorrectedCurrentTime
 * @param {{openTime: string, closeTime: string}} scheduleDate
 * @returns {{isOpenNow: boolean, text: string}}
 * @private
 */
const _getOpenDataFromDate = (timezoneCorrectedCurrentTime: Moment, scheduleDate: {openTime: string, closeTime: string}, isFuture: boolean): {isOpenNow: boolean, text: string, timeString: string, restaurantLocationId: string} => {
  
  let isOpenNow = false;
  let isOpenLaterToday: boolean = false;
  let openCloseText = 'Closed';
  let openCloseTimeString = '';
  
  const parsedOpenTime = _parseTimeString(scheduleDate.openTime);
  const parsedCloseTime = _parseTimeString(scheduleDate.closeTime);
  
  let momentOpenTime = _getMomentObjectFromTime(timezoneCorrectedCurrentTime, parsedOpenTime.hour, parsedOpenTime.minute);
  let momentCloseTime = _getMomentObjectFromTime(timezoneCorrectedCurrentTime, parsedCloseTime.hour, parsedCloseTime.minute);
  if(parsedOpenTime.hour > parsedCloseTime.hour) {
    momentOpenTime = momentOpenTime.add(-1, 'd');
  }
  
  if(isFuture) {
    const timeString = _calculateTimeString(momentOpenTime);
    openCloseText = `Opens at ${timeString}`;
    openCloseTimeString = timeString;
    
    return {
      isOpenNow: false,
      isOpenLaterToday: false,
      text: openCloseText,
      timeString: openCloseTimeString,
      restaurantLocationId: ''
    };
  }
  
  if(timezoneCorrectedCurrentTime.isBefore(momentOpenTime)) {
    isOpenNow = false;
    
    const timeString = _calculateTimeString(momentOpenTime);
    openCloseText = `Opens at ${timeString}`;
    isOpenLaterToday = true;
    openCloseTimeString = timeString;
  }
  
  if(timezoneCorrectedCurrentTime.isBetween(momentOpenTime, momentCloseTime)) {
    isOpenNow = true;
    
    const timeString = _calculateTimeString(momentCloseTime);
    openCloseText = `Open until ${timeString}`;
    openCloseTimeString = timeString;
  }
  
  if(timezoneCorrectedCurrentTime.isSameOrAfter(momentCloseTime)) {
    isOpenNow = false;
    openCloseText = 'Closed';
  }
  
  return {
    isOpenNow: isOpenNow,
    isOpenLaterToday: isOpenLaterToday,
    text: openCloseText,
    timeString: openCloseTimeString,
    restaurantLocationId: ''
  };
};


const timeblockHourSelector = (item: ScheduleTimeblockType) => {

  const parsedTime = _parseTimeString(item.openTime);
  
  return parsedTime.hour;
};

/**
 *
 * @param {Moment} timezoneCorrectedCurrentTime
 * @param scheduleDate
 * @param {string} currentDayOfWeek
 * @param {boolean} isToday
 * @param {boolean} isFuture
 * @returns {{isOpenNow: boolean, text: string, timeString: string, restaurantLocationId: string}}
 * @private
 */
const _getOpenDataFromTimeblocks = (timezoneCorrectedCurrentTime: Moment, scheduleDate, currentDayOfWeek: string, isToday: boolean, isFuture: boolean): {isOpenNow: boolean, text: string, timeString: string, restaurantLocationId: string} => {
  
  let isOpenNow: boolean = false;
  let isOpenLaterToday: boolean = false;
  let openCloseText = 'Closed';
  let openCloseTimeString = '';
  let restaurantLocationId = '';
  
  const sortedTimeblocks = orderBy(scheduleDate.timeblocks, [timeblockHourSelector], ['asc']);
  
  let scheduleTimeblock;
  const timeblocksLength = sortedTimeblocks.length;
  const currentTimeDay = timezoneCorrectedCurrentTime.format('dddd').toLocaleLowerCase();
  for(let index = 0; index < timeblocksLength; index++) {
    scheduleTimeblock = sortedTimeblocks[index];
    
    const parsedOpenTime = _parseTimeString(scheduleTimeblock.openTime);
    const parsedCloseTime = _parseTimeString(scheduleTimeblock.closeTime);
  
    let momentOpenTime = _getMomentObjectFromTime(timezoneCorrectedCurrentTime, parsedOpenTime.hour, parsedOpenTime.minute);
    let momentCloseTime = _getMomentObjectFromTime(timezoneCorrectedCurrentTime, parsedCloseTime.hour, parsedCloseTime.minute);
    
    if(parsedOpenTime.hour > parsedCloseTime.hour) {
      if (!isToday || currentDayOfWeek !== currentTimeDay) {
        momentOpenTime = momentOpenTime.add(-1, 'd');
      }
      
      if (currentDayOfWeek === currentTimeDay && isToday) {
        momentCloseTime = momentCloseTime.add(1, 'd');
      }
    } else {
      if(!isToday) {
        momentOpenTime = momentOpenTime.add(-1, 'd');
        momentCloseTime = momentCloseTime.add(-1, 'd');
      }
    }
    
    if(isFuture) {
      const timeString = _calculateTimeString(momentOpenTime);
      openCloseText = `Opens at ${timeString}`;
      isOpenLaterToday = false;
      openCloseTimeString = timeString;
      restaurantLocationId = scheduleTimeblock.restaurantLocationId;
  
      return {
        isOpenNow: false,
        isOpenLaterToday,
        text: openCloseText,
        timeString: openCloseTimeString,
        restaurantLocationId: restaurantLocationId || ''
      };
    }

    //let opensLaterToday: boolean = false;
    if (timezoneCorrectedCurrentTime.isBefore(momentOpenTime)) {
      isOpenNow = false;
      //opensLaterToday = true;
      
    
      const timeString = _calculateTimeString(momentOpenTime);
      openCloseText = `Opens at ${timeString}`;
      isOpenLaterToday = true;
      openCloseTimeString = timeString;
      restaurantLocationId = scheduleTimeblock.restaurantLocationId;
    }
  
    if (timezoneCorrectedCurrentTime.isBetween(momentOpenTime, momentCloseTime)) {
      isOpenNow = true;
      const timeString = _calculateTimeString(momentCloseTime);
      openCloseText = `Open until ${timeString}`;
      openCloseTimeString = timeString;
      restaurantLocationId = scheduleTimeblock.restaurantLocationId;
    }
    
    if(isOpenNow) {
      break;
    }
  
    if (timezoneCorrectedCurrentTime.isSameOrAfter(momentCloseTime)) {
      isOpenNow = false;
      openCloseText = 'Closed';
      restaurantLocationId = scheduleTimeblock.restaurantLocationId;
    }
  }
  
  return {
    isOpenNow: isOpenNow,
    isOpenLaterToday,
    text: openCloseText,
    timeString: openCloseTimeString,
    restaurantLocationId: restaurantLocationId || ''
  };
};

/**
 *
 * @param {Moment} momentObject
 * @param {{removeZeroMinutes: boolean}} options
 * @returns {string}
 * @private
 */
export const _calculateTimeString = (momentObject: Moment, options?: {removeZeroMinutes: boolean} = {removeZeroMinutes: true}): string => {
  const openHour: number = momentObject.format('h');
  const openMinute: number = momentObject.format('mm');
  let openMinuteString = '';
  if(!options.removeZeroMinutes || openMinute > 0) {
    openMinuteString = `:${openMinute}`;
  }
  const timeOfDay: string = momentObject.format('a');
  
  return `${openHour}${openMinuteString}${timeOfDay}`;
};

/**
 *
 * @param {string} timeString
 * @returns {{hour: number, minute: number}}
 * @private
 */
export const _parseTimeString = (timeString: string): {hour: number, minute: number} => {
  const splitTime: string[] = (timeString || '00:00').split(':');
  const parsedHour: number = parseInt(splitTime[0], 10);
  const parsedMinute: number = parseInt(splitTime[1], 10);
  
  return {
    hour: parsedHour,
    minute: parsedMinute
  };
};

/**
 *
 * @param {Moment} baseTime
 * @param {number} hour
 * @param {number} minute
 * @returns {moment.Moment}
 * @private
 */
export const _getMomentObjectFromTime = (baseTime: Moment, hour: number, minute: number): Moment => {
  
  return moment(baseTime)
    .hour(hour)
    .minute(minute)
    .second(0);
};

/**
 *
 * @param {string} timeString
 * @returns {string}
 */
export const get12HourTimeFromString = (timeString: string): string => {

  const result: {hour: number, minute: number} = _parseTimeString(timeString);
  
  const momentTime: Moment = _getMomentObjectFromTime(moment(), result.hour, result.minute);
  
  return _calculateTimeString(momentTime, {removeZeroMinutes: false});
};

/**
 *
 * @param {RestaurantType} restaurant
 */
export const getRestaurantScheduleWithOverrides = (restaurant: RestaurantType): any[] => {

  const scheduleObjects = [];
  
  if(isEmpty(restaurant)) {
    return scheduleObjects;
  }
  
  const scheduleDays = Object.keys(restaurant.openSchedule || {});
  
  scheduleDays.forEach((scheduleDay) => {
    const scheduleDayObject = restaurant.openSchedule[scheduleDay];
  
    scheduleObjects.push({
      ...scheduleDayObject,
      scheduleDayKey: scheduleDay
    });
  });
  
  
  return scheduleObjects;
};

/**
 *
 * @param {RestaurantType} restaurant
 * @param {string} date
 * @returns {*}
 */
export const getHoursForDate = (restaurant: RestaurantType, date: string) => {
  const todayHours = {
    isOpen: false,
    hours: []
  };
  
  if(isEmpty(restaurant)) {
    return todayHours;
  }
  
  let foundOverride = false;
  
  const scheduleOverrides: RestaurantScheduleOverrideType[] = Object.values(restaurant.scheduleOverrides || {});
  for(let i = 0; i < scheduleOverrides.length; i++) {
    const scheduleOverride = scheduleOverrides[i];
    
    if(scheduleOverride.date === date) {
      foundOverride = true;
      todayHours.isOpen = scheduleOverride.isOpen;
      if(todayHours.isOpen) {
        todayHours.hours = scheduleOverride.timeblocks;
      }
      
      break;
    }
  }
  
  if(foundOverride) {
    return todayHours;
  }
  
  const dayOfWeek: string = moment(date, "DD-MM-YYYY").format('dddd').toLocaleLowerCase();
  if(dayOfWeek in restaurant.openSchedule) {
    const schedule: RestaurantOpenScheduleItemType = restaurant.openSchedule[dayOfWeek];
    
    todayHours.isOpen = schedule.isOpen;
    if(todayHours.isOpen) {
      todayHours.hours = schedule.timeblocks;
    }
  }
  
  return todayHours;
};

/**
 *
 * @param {string} date
 * @returns {string}
 */
export const formatDate = (date: string): string => {

  const momentObject = moment(date);
  const dateString = momentObject.format('MM/DD/YYYY');
  const timeString = momentObject.format('h:mm a');
  
  
  return `${dateString} ${timeString}`;

};

/**
 *
 * @returns {string}
 */
export const getDateForOverrideLookup = (): string => {
  return moment().format('DD-MM-YYYY');
};

/**
 *
 * @returns {string}
 */
export const getTodayDay = (): string => {
  return moment().format('dddd').toLocaleLowerCase();
};

/**
 *
 * @param {RestaurantType} restaurant
 * @return {Array}
 * @private
 */
export const buildScheduleToDisplay = (restaurant: RestaurantType): any[] => {
  
  if(isEmpty(restaurant)) {
    return [];
  }
  
  const date = getDateForOverrideLookup();
  const dayOfWeek = getTodayDay();
  console.log(dayOfWeek);
  const overrides = Object.values(restaurant.scheduleOverrides || {});
  const overridesByDate = keyBy(overrides, 'date');
  
  const scheduleDays = Object.keys(restaurant.openSchedule);
  
  const results = [];
  scheduleDays.forEach((scheduleDay: string) => {
    const scheduleObject: RestaurantOpenScheduleItemType = restaurant.openSchedule[scheduleDay];
    
    const newObject = {
      day: scheduleDay,
      isOpen: !!scheduleObject.isOpen,
      isCurrent: (scheduleDay === dayOfWeek),
      isOverride: false,
      timeblocks: scheduleObject.timeblocks || []
    };
    
    if(date in overridesByDate) {
      const overrideObject: RestaurantScheduleOverrideType = overridesByDate[date];
      newObject.isOverride = true;
      newObject.isOpen = overrideObject.isOpen;
      newObject.timeblocks = overrideObject.timeblocks;
    }
    
    results.push(newObject);
  });
  
  return results;
};

/**
 *
 * @param {{addedDateTime: string}} item
 * @returns {number}
 */
export const convertDateToUnixTimestamp = (item: {addedDateTime: string}): number => {
  
  const momentPlacedDateTime = moment(item.addedDateTime);
  
  return momentPlacedDateTime.unix();
};
