//@flow
import React from 'react';
import styles from './MenuChoiceOption.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cns from 'classnames/bind';

import type { MenuItemChoiceOptionType, MenuItemChoiceType } from '../../types';

const cx = cns.bind(styles);

type MenuChoiceOptionProps = {
  choice: MenuItemChoiceType;
  option: MenuItemChoiceOptionType;
  displayPrice: string;
  isOptionSelected: boolean;
  onSelectChoiceOption: (promptType: string, choiceId: string, optionId: string, optionLimit: number) => void;
  deselectable?: boolean;
}

const MenuChoiceOption = React.memo<MenuChoiceOptionProps>((props: MenuChoiceOptionProps) => {
  const { choice, option, displayPrice, isOptionSelected, onSelectChoiceOption, deselectable = false } = props;
  
  let unselectedOptionIcon = ['fal', 'circle'];
  let selectedOptionIcon = ['far', 'dot-circle'];
  if(deselectable) {
    unselectedOptionIcon = ['fal', 'square'];
    selectedOptionIcon = ['fas', 'check-square'];
  }
  
  return (
    <div key={option.id} className={styles.selectableOption} onClick={() => onSelectChoiceOption(choice.promptType, choice.id, option.id, choice.optionLimit)}>
      <div>
        { !isOptionSelected && <FontAwesomeIcon icon={unselectedOptionIcon} size={'lg'} className={styles.icon} /> }
        { !!isOptionSelected && <FontAwesomeIcon icon={selectedOptionIcon} size={'lg'} className={cx(styles.icon, styles.selected)} /> }
      </div>
      <div className={styles.title}>{option.name}</div>
      <div className={styles.price}>{displayPrice}</div>
    </div>
  );
});

export { MenuChoiceOption };
