//@flow
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import type { OrderType } from '../types';

export const makeGetTemporaryOrderForPickup = () => {
  return createSelector(
    (state) => state.incompleteOrders.byId,
    (_, restaurantId) => restaurantId,
    (incompleteOrdersById, restaurantId) => {
      
      let returnOrder = {};
      if(isEmpty(restaurantId)) {
        return returnOrder;
      }
      
      const incompleteOrders: OrderType[] = Object.values(incompleteOrdersById || {});
      
      const incompleteOrdersLength = incompleteOrders.length;
      
      
      let currentOrder;
      for(let currentIndex = 0; currentIndex < incompleteOrdersLength; currentIndex++) {
        currentOrder = incompleteOrders[currentIndex];
        if(currentOrder.restaurantId === restaurantId && isEmpty(currentOrder.groupOrderId)) {
          returnOrder = incompleteOrders[currentIndex];
          break;
        }
        
      }
      
      return returnOrder;
    }
  );
};
