import React from 'react';
import { isEmpty } from 'lodash';
import { Badge } from 'reactstrap';
import cns from 'classnames/bind';

import styles from './MenuChoiceTitle.module.scss';

const cx = cns.bind(styles);

type MenuChoiceTitleProps = {
  text: string;
  subtitle?: string;
  errorMessage?: string;
  halfBuffer?: boolean;
  backgroundColor?: any;
  isRequired?: boolean;
}

const MenuChoiceTitle = (props: MenuChoiceTitleProps) => {
  const {text, subtitle = '', halfBuffer = false, backgroundColor = null, isRequired = false} = props;
  
  let subtitleSection = null;
  if (!isEmpty(subtitle)) {
    subtitleSection = (
      <div className={styles.subtitleStyle}>
        {subtitle}
      </div>
    )
  }
  
  let backgroundStyle = {};
  if (!isEmpty(backgroundColor)) {
    backgroundStyle = {
      backgroundColor
    };
  }
  
  let badgeText = 'Optional';
  if(isRequired) {
    badgeText = 'Required';
  }
  
  return (
    <div className={styles.sectionTitleContainer}>
      <div className={cx([styles.titleContainer, halfBuffer ? styles.textStyleHalfBuffer : styles.textStyleFullBuffer, backgroundStyle])}>
        <div className={styles.textStyle}>
          {text}
        </div>
        {subtitleSection}
        <div className={styles.badgeContainer}>
          <Badge className={cx(isRequired ? styles.badgeRequired : styles.badgeOptional, styles.badge)} pill={true}>
            <div style={{color: 'white', fontSize: 14}}>{badgeText}</div>
          </Badge>
        </div>
      </div>
    </div>
  );
};

export { MenuChoiceTitle };
