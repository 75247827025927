import React from 'react';
import ReactDOM from 'react-dom';

//CSS
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import { loadIcons } from './utils/IconUtils';

import { Root } from './containers/Root';
import * as serviceWorker from './serviceWorker';

loadIcons();

const rootEl = document.getElementById('root');


ReactDOM.render(<Root />, rootEl);


if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    const NextRoot = require('./containers/Root').Root;
    ReactDOM.render(
      <NextRoot />,
      rootEl
    )
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
