//@flow
import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

import { QbInfiniteHits } from './QbInfiniteHits';
import { isEmpty } from 'lodash';

import styles from './QbSearchContent.module.scss';
import { SearchResultItemLoader } from './SearchResultItemLoader';


const QbSearchContent = connectStateResults(({ searchState, searchResults, searching, onHitClick, timestamp, onSearchClear }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;
  
  console.log(`searchResults: ${searchResults && searchResults.nbHits}`);
  
  let content = null;
  if(hasResults) {
    content = (
      <div>
        <QbInfiniteHits onHitClick={onHitClick} timestamp={timestamp}/>
      </div>
    );
  } else {
    if(searching) {
      content = (
        <div>
          <SearchResultItemLoader />
          <SearchResultItemLoader />
        </div>
      );
    } else {
      content = (
        <div>
          No results has been found for <span className={styles.noResultsText}>{searchState.query}</span>
        </div>
      );
    }
  }
  
  return (
    <div className={styles.resultsContainer}>
      {!isEmpty(searchState.query) && (
        <div className={styles.searchResultString}>Results for "{searchState.query}"
          <span className={styles.clearSearch} onClick={onSearchClear}>Clear Search</span>
        </div>) }
      { content }
    </div>
  );
});

export { QbSearchContent };
